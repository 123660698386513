import * as RA from "react-admin";
import useStyles from "../../hooks/Styles";
import { parse } from "query-string";
import HtmlSanitize from "../../common/HtmlSanitize";
import Typography from "@material-ui/core/Typography";
import CustomToolBar from "../../layout/CustomToolBar";
import ImageUploader from "../../common/ImageUploader";
import { SeatingType } from "../../../utils/enums";

const Form = ({ view = "create", ...props }) => {
  const { record } = props;
  const params = parse(`?${location.hash.split("?")[1]}`);
  const venueId = record.venueId
    ? record.venueId
    : parseInt(params.venueId as string);
  const assetId = record.assetId
    ? record.assetId
    : parseInt(params.assetId as string);

  return venueId ? (
    <VenueAssetPhotoForm view={view} venueId={venueId} {...props} />
  ) : (
    <AssetPhotoForm view={view} assetId={assetId} {...props} />
  );
};

// @ts-ignore
const VenueAssetPhotoForm = ({ view, venueId, ...props }) => {
  const styles = useStyles();
  const venue = RA.useGetOne("venues", venueId).data as RA.Record;

  if (!venue) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <RA.SimpleForm
      {...props}
      initialValues={{ venueId }}
      toolbar={
        <CustomToolBar
          view={view}
          deleteRedirectPath={`/venues/${venueId}/3`}
        />
      }
      redirect={`/venues/${venueId}/3`}
    >
      {view === "create" && (
        <HtmlSanitize>
          <Typography variant="h5" className={styles.header}>
            New Representative Asset Photo: {venue.name}
          </Typography>
        </HtmlSanitize>
      )}
      {view === "edit" && (
        <RA.ReferenceField label="Venue" source="venueId" reference="venues">
          <RA.TextField source="name" />
        </RA.ReferenceField>
      )}
      <ImageUploader
        source="imageUrl"
        label="Asset Photo"
        helperText="Landscape Style Image. Min 1000px wide."
        validate={RA.required()}
      />
      <RA.SelectInput
        source="seatingType"
        choices={SeatingType}
        validate={RA.required()}
      />
      <RA.SelectInput
        source="venueSection"
        choices={venue?.sectionNames
          .map((obj: { field: string }) => obj.field)
          .map((choice: string) => ({
            id: choice,
            name: choice,
          }))}
      />
      <RA.TextInput source="description" />
    </RA.SimpleForm>
  );
};

// @ts-ignore
const AssetPhotoForm = ({ view, assetId, ...props }) => {
  const styles = useStyles();
  const asset = RA.useGetOne("assets", assetId).data as RA.Record;

  if (!asset) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <RA.SimpleForm
      {...props}
      initialValues={{
        assetId,
        venueSection: asset.venueSection,
        seatingType: asset.seatingType,
      }}
      toolbar={
        <CustomToolBar
          view={view}
          deleteRedirectPath={`/assets/${assetId}/2`}
        />
      }
      redirect={`/assets/${assetId}/2`}
    >
      {view === "create" && (
        <HtmlSanitize>
          <Typography variant="h5" className={styles.header}>
            New Verified Asset Photo: {asset.name}
          </Typography>
        </HtmlSanitize>
      )}
      {view === "edit" && (
        <RA.ReferenceField label="Asset" source="assetId" reference="assets">
          <RA.TextField source="name" />
        </RA.ReferenceField>
      )}
      <ImageUploader
        source="imageUrl"
        label="Asset Photo"
        helperText="Landscape Style Image. Min 1000px wide."
        validate={RA.required()}
      />
      <RA.TextInput source="description" />
    </RA.SimpleForm>
  );
};

export default Form;
