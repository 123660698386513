import * as RA from "react-admin";
import useStyles from "../../hooks/Styles";
import CustomPagination from "../../layout/CustomPagination";

export default (props: RA.ListProps) => {
  const styles = useStyles();
  const filters = [
    <RA.TextInput
      label="Search by venue name"
      source="q"
      alwaysOn
      className={styles.search}
    />,
  ];

  return (
    <RA.List
      filters={filters}
      pagination={<CustomPagination />}
      perPage={50}
      {...props}
    >
      <RA.Datagrid rowClick="edit">
        <RA.TextField source="id" />
        <RA.TextField source="name" />
        <RA.EditButton />
        <RA.ShowButton />
      </RA.Datagrid>
    </RA.List>
  );
};
