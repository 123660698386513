import * as RA from "react-admin";
import useStyles from "../hooks/Styles";
import HtmlSanitize from "./HtmlSanitize";
import Typography from "@material-ui/core/Typography";

// @ts-ignore
const ImageUploader = ({ source, label, ...props }) => {
  const styes = useStyles();

  return (
    <HtmlSanitize>
      {(props.record?.imageUrl ||
        props.record?.featuredImage ||
        props.record?.field) && (
        <HtmlSanitize>
          <Typography>Existing Photo:</Typography>
          <RA.ImageField source={source} label="Existing Photo" />
        </HtmlSanitize>
      )}
      <RA.ImageInput
        source={source}
        label={label}
        accept="image/*"
        className={styes.input}
        helperText={props.helperText}
      >
        <RA.ImageField source="src" />
      </RA.ImageInput>
    </HtmlSanitize>
  );
};

export default ImageUploader;
