const ErrorPage = () => {
  return (
    <div>
      <h1>Error</h1>
      <p>You are not authorized to view this page.</p>
      <p>
        If you believe this message to be incorrect, clear your browser cache
        and try signing in again to{" "}
        <a href={process.env.REACT_APP_AUTH0_REDIRECT_URI}>
          {process.env.REACT_APP_AUTH0_REDIRECT_URI}
        </a>
        .
      </p>
      <p>
        Or contact us at{" "}
        <a href="mailto:partners@suitehop.com">partners@suitehop.com</a>
      </p>
    </div>
  );
};

export default ErrorPage;
