import * as RA from "react-admin";
import CustomPagination from "../../layout/CustomPagination";

export default (props: RA.ListProps) => (
  <RA.List
    pagination={<CustomPagination />}
    perPage={50}
    bulkActionButtons={false}
    {...props}
  >
    <RA.Datagrid rowClick="edit">
      <RA.NumberField source="margin" label="Default Ticket Evo Margin" />
      <RA.NumberField source="privateSuiteThreshold" />
      <RA.EditButton />
      <RA.ShowButton />
    </RA.Datagrid>
  </RA.List>
);
