import { useForm } from "react-final-form";
import React, { useEffect, useState } from "react";
import HtmlSanitize from "./HtmlSanitize";
import Typography from "@material-ui/core/Typography";
import * as RA from "react-admin";
import MapPinInput from "./MapPinInput";
import NumberInput from "./NumberInput";

const MapSection = ({
  styles,
  locationHidden,
  xPoint,
  yPoint,
  maps,
  activeTabIndex,
}: any) => {
  const form = useForm();
  const defaultMap =
    maps.length > 1
      ? maps.find((m: Record<any, any>) => m.category === "default")
      : maps[0];
  const [xPointState, setXPointState] = useState(xPoint);
  const [yPointState, setYPointState] = useState(yPoint);
  const [locationHiddenState, setLocationHiddenState] = useState(
    locationHidden || false
  );
  const [displayMap, setDisplayMap] = useState(true);
  const [currentMapCategory, setCurrentMapCategory] = useState(
    defaultMap.category
  );
  const [currentMapImage, setCurrentMapImage] = useState(defaultMap.imageUrl);
  const isMapTab = window.location.hash.split("/")[3] === `${activeTabIndex}`;

  useEffect(() => {
    form.change("xPoint", xPointState);
    form.change("yPoint", yPointState);
  }, [xPointState, yPointState]);

  useEffect(() => {
    if (locationHiddenState) {
      setDisplayMap(false);
      setXPointState(0);
      setYPointState(0);
    } else {
      setDisplayMap(true);
      setXPointState(xPoint);
      setYPointState(yPoint);
    }
  }, [locationHiddenState, xPoint, yPoint]);

  return (
    <>
      <HtmlSanitize>
        <Typography variant="h5" className={styles.header}>
          Venue Map:
        </Typography>
      </HtmlSanitize>
      <HtmlSanitize>
        <RA.BooleanInput
          source="locationHidden"
          label="Display on the website with no pin on the map"
          initialValue={locationHidden || false}
          onChange={(val) => {
            setLocationHiddenState(val);
          }}
        />
      </HtmlSanitize>
      <HtmlSanitize>
        <select
          name="venueMaps"
          id="venueMaps"
          className={styles.marginBottom}
          onChange={(e) => {
            const updateMap = maps.find(
              (map: Record<any, any>) => map.category === e.target.value
            );
            setCurrentMapCategory(updateMap.category);
            setCurrentMapImage(updateMap.imageUrl);
          }}
          defaultValue={currentMapCategory}
        >
          {maps.map((map: Record<any, any>, index: number) => (
            <option key={index} value={map.category}>
              {map.category}
            </option>
          ))}
        </select>
      </HtmlSanitize>
      <HtmlSanitize>
        <NumberInput source="xPoint" disabled className={styles.displayBlock} />
      </HtmlSanitize>
      <HtmlSanitize>
        <NumberInput source="yPoint" disabled className={styles.displayBlock} />
      </HtmlSanitize>
      {displayMap && (
        <MapPinInput
          image={currentMapImage}
          isActiveTab={isMapTab}
          x={xPointState || 0}
          y={yPointState || 0}
          onUpdate={({ lat, lng }) => {
            setXPointState(lat);
            setYPointState(lng);
          }}
        />
      )}
    </>
  );
};

export default MapSection;
