import * as RA from "react-admin";
import { useAuth0 } from "@auth0/auth0-react";
import { makeStyles } from "@material-ui/core/styles";
import Logo from "./SuitehopLogo";

const useStyles = makeStyles({
  title: {
    flex: 1,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  spacer: {
    flex: 1,
  },
});

const CustomLayout = (props: any) => (
  <RA.Layout
    appBar={CustomAppBar}
    menu={RA.Menu}
    notification={RA.Notification}
    {...props}
  />
);

const CustomAppBar = (props: RA.AppBarProps) => {
  const auth = useAuth0();
  const classes = useStyles();

  return (
    <RA.AppBar {...props} userMenu={false}>
      <Logo />
      <span className={classes.spacer} />
      <button onClick={() => auth.logout()}>Logout</button>
    </RA.AppBar>
  );
};

export default CustomLayout;
