import * as RA from "react-admin";

export default (props: RA.ShowProps) => (
  <RA.Show {...props}>
    <RA.SimpleShowLayout>
      <RA.ReferenceField label="Venue" source="venueId" reference="venues">
        <RA.TextField source="name" />
      </RA.ReferenceField>
      <RA.ReferenceField label="Asset" source="assetId" reference="assets">
        <RA.TextField source="name" />
      </RA.ReferenceField>
      <RA.TextField source="section" />
      <RA.TextField source="row" />
    </RA.SimpleShowLayout>
  </RA.Show>
);
