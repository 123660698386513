import * as RA from "react-admin";
import PersistedNameContainer from "../../common/PersistedNameContainer";

export default (props: RA.ShowProps) => (
  <RA.Show {...props}>
    <PersistedNameContainer name="Global Settings">
      <GlobalSettingsShow {...props} />
    </PersistedNameContainer>
  </RA.Show>
);

const GlobalSettingsShow = ({ ...props }) => (
  <RA.SimpleShowLayout {...props}>
    <RA.NumberField source="margin" label="Default Ticket Evo margin" />
    <RA.NumberField source="privateSuiteThreshold" />
  </RA.SimpleShowLayout>
);
