import * as RA from "react-admin";

const PercentField = (props: RA.FieldProps) => {
  const { source } = props;
  const record = RA.useRecordContext(props);

  if (!record || !source) {
    return null;
  }

  const value = record[source];

  if (value > 1) {
    record[source] = value / 100;
  }

  return (
    <RA.NumberField
      record={record}
      source={source}
      options={{ style: "percent" }}
      {...props}
    />
  );
};

PercentField.defaultProps = {
  addLabel: true,
};

export default PercentField;
