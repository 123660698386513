import * as RA from "react-admin";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import Add from "@material-ui/icons/Add";

type CreateListingLinkType = RA.ButtonProps & { referenceType: string };
const CreateListingLink = ({
  referenceType,
  ...props
}: CreateListingLinkType) => {
  const record = props.record as Record<any, any>;
  const reference =
    referenceType === "event" ? { eventId: record.id } : { assetId: record.id };
  return (
    <Button
      // className={styles.button}
      color="primary"
      onClick={(e: React.SyntheticEvent) => e.stopPropagation()}
      component={Link}
      to={{
        pathname: "/listings/create",
        search: `?source=${JSON.stringify({
          ...reference,
        })}`,
      }}
    >
      <Add />
      Create Listing
    </Button>
  );
};

export default CreateListingLink;
