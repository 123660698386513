import * as RA from "react-admin";
import { BookingMethod, ListingStatus } from "../../../utils/enums";
import { convertShTzToIntlTz } from "../../../utils/timeZones";

const OccursAtWithVenueTimeZone = () => {
  const eventReference = RA.useRecordContext();
  const { referenceRecord: venueReference } = RA.useReference({
    reference: "venues",
    id: eventReference.venueId,
  }) as Record<any, any>;

  if (!venueReference) {
    return null;
  }

  return (
    <RA.DateField
      source="occursAt"
      locales="en-us"
      options={{ timeZone: convertShTzToIntlTz(venueReference.timeZone) }}
    />
  );
};

const ListingsDataGrid = ({
  showEventsData = true,
  showStatusColumn = false,
  showAssetData = false,
}) => {
  return (
    <RA.Datagrid rowClick="edit">
      <RA.TextField source="id" />
      {showAssetData && (
        <RA.ReferenceField label="Asset" source="assetId" reference="assets">
          <RA.TextField source="name" />
        </RA.ReferenceField>
      )}
      {showStatusColumn && (
        <RA.SelectField source="status" choices={ListingStatus} />
      )}
      {showEventsData && (
        <RA.ReferenceField label="Event" source="eventId" reference="events">
          <RA.TextField source="name" />
        </RA.ReferenceField>
      )}
      {showEventsData && (
        <RA.ReferenceField
          label="Date"
          source="eventId"
          reference="events"
          link={false}
        >
          <OccursAtWithVenueTimeZone />
        </RA.ReferenceField>
      )}
      <RA.DateField
        source="createdAt"
        locales="en-us"
        options={{ timeZone: "America/Los_Angeles" }}
      />
      <RA.TextField source="accessType" />
      <RA.SelectField source="bookingMethod" choices={BookingMethod} />
      <RA.NumberField
        source="wholesalePrice"
        options={{ style: "currency", currency: "USD" }}
      />

      <RA.EditButton />
      <RA.ShowButton />
      <RA.DeleteButton />
    </RA.Datagrid>
  );
};

export default ListingsDataGrid;
