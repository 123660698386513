import * as RA from "react-admin";
import useStyles from "../hooks/Styles";
import { Delete } from "@material-ui/icons";

const CustomToolBar = (props: Record<any, any>) => {
  const styles = useStyles();
  const notify = RA.useNotify();
  const redirect = RA.useRedirect();
  const [deleteOne, { loading }] = RA.useDelete(
    props["resource"],
    props["record"].id,
    props["record"],
    {
      action: RA.CRUD_DELETE,
      onSuccess: () => {
        notify("Deleted", "info", undefined, true, 5000);
        redirect(
          props.deleteRedirectPath
            ? props.deleteRedirectPath
            : props["basePath"]
        );
      },
      onFailure: (error) => {
        notify(error.message, "warning", undefined, false, 5000);
      },
    }
  );

  return (
    <RA.Toolbar {...props} className={styles.toolBar}>
      <RA.SaveButton />
      {props.view === "edit" && !props.noDelete && (
        <RA.Button
          onClick={deleteOne}
          disabled={loading}
          label="Delete"
          className={styles.deleteButton}
        >
          <Delete />
        </RA.Button>
      )}
    </RA.Toolbar>
  );
};

export default CustomToolBar;
