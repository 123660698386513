import * as RA from "react-admin";
import useStyles from "../../hooks/Styles";
import EventsDataGrid from "./eventsDataGrid";
import CustomPagination from "../../layout/CustomPagination";

export default (props: RA.ListProps) => {
  const styles = useStyles();
  const filters = [
    <RA.TextInput
      label="Search by event name"
      source="q"
      alwaysOn
      className={styles.search}
    />,
    <RA.BooleanInput source="expired" label="Expired" alwaysOn />,
  ];

  return (
    <RA.List
      filters={filters}
      pagination={<CustomPagination />}
      perPage={50}
      {...props}
      filterDefaultValues={{ expired: false }}
      sort={{ field: "occursAt", order: "ASC" }}
    >
      <EventsDataGrid />
    </RA.List>
  );
};
