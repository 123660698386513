import { useState } from "react";
import HtmlSanitize from "../../../common/HtmlSanitize";
import useStyles from "../../../hooks/Styles";

// @ts-ignore
export default function OrderDataAtTimeOfPurchase({ props }) {
  const styles = useStyles();
  const [showData, setShowData] = useState(false);
  if (!props.record?.metadata?.orderDataAtTimeOfPurchase) {
    return null;
  }

  return (
    <>
      <HtmlSanitize>
        <button
          onClick={() => setShowData(!showData)}
          className={styles.marginBottom}
        >
          {showData ? "Hide" : "Show"} order data at time of purchase
        </button>
      </HtmlSanitize>
      {showData && (
        <HtmlSanitize>
          <pre>
            <code>
              {JSON.stringify(
                props.record.metadata.orderDataAtTimeOfPurchase,
                null,
                2
              )}
            </code>
          </pre>
        </HtmlSanitize>
      )}
    </>
  );
}
