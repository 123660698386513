import * as RA from "react-admin";
import { Visibility, Event } from "@material-ui/icons";
import useAuthentication from "../../hooks/useAuthentication";
import { getDataFromCustomRoute } from "../../providers/DataProvider";
import ListingForm from "./Form";
import PersistedNameContainer from "../../common/PersistedNameContainer";
import React from "react";
import { useRefresh } from "react-admin";
import {
  boxOfficeCheckoutUrl,
  boxOfficeListingUrl,
  triggerTevoListingSyncUrl,
} from "../../../utils/urls";

const Container = (props: any) => {
  const event = RA.useGetOne("events", props.record.eventId).data as RA.Record;

  if (!event) {
    return null;
  }

  const listingLink = props.record
    ? boxOfficeListingUrl(event.slug, props.record.id)
    : null;
  const checkoutlink = props.record
    ? boxOfficeCheckoutUrl(props.record.id)
    : null;

  return (
    <PersistedNameContainer
      name={`Listing event: ${event.name}`}
      link={listingLink}
      checkoutlink={checkoutlink}
      {...props}
    >
      <ListingForm view="edit" />
    </PersistedNameContainer>
  );
};

const EditActions = ({ listingId }: { listingId: string | undefined }) => {
  const { user, authToken } = useAuthentication();
  const refresh = useRefresh();

  if (!listingId || !authToken) {
    return null;
  }

  return (
    <RA.TopToolbar>
      <RA.Button
        onClick={() =>
          window.location.assign(
            `${process.env.REACT_APP_APPLICATION_URL}/#/listings/${listingId}/show`
          )
        }
        label={"Show"}
      >
        <Visibility />
      </RA.Button>
      <RA.Button
        onClick={async () => {
          const url = triggerTevoListingSyncUrl(listingId);
          await getDataFromCustomRoute(url, authToken, user);
          alert("Sync with tevo scheduled");
          refresh();
        }}
        label="Schedule Sync with Tevo"
      >
        <Event />
      </RA.Button>
    </RA.TopToolbar>
  );
};

export default (props: RA.EditProps) => (
  <RA.Edit {...props} actions={<EditActions listingId={props.id} />}>
    <Container />
  </RA.Edit>
);
