import * as RA from "react-admin";
import CustomToolBar from "../../../layout/CustomToolBar";
import NumberInput from "../../../common/NumberInput";
import { CateringType, SeatingType } from "../../../../utils/enums";
import useStyles from "../../../hooks/Styles";
import { parse } from "query-string";
import Typography from "@material-ui/core/Typography";
import HtmlSanitize from "../../../common/HtmlSanitize";
import BooleanInputs from "../../../common/AssetBooleanInputs";
import React, { useState } from "react";
import AssetsDataGrid from "../../assets/assetsDataGrid";
import CustomPagination from "../../../layout/CustomPagination";

const Form = ({ view = "create", ...props }) => {
  const styles = useStyles();
  const { record } = props;
  const params = parse(`?${location.hash.split("?")[1]}`);
  const [seatingTypeVal, setSeatingTypeVal] = useState(record.seatingType);
  const venueId = record.venueId
    ? record.venueId
    : parseInt(params.venueId as string);
  const venue = RA.useGetOne("venues", venueId).data as RA.Record;

  if (!venue) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <RA.TabbedForm
      {...props}
      toolbar={<CustomToolBar view={view} />}
      redirect="list"
    >
      <RA.FormTab label="Properties">
        {view === "create" && (
          <HtmlSanitize>
            <Typography variant="h5" className={styles.header}>
              New Asset Template: {venue.name}
            </Typography>
          </HtmlSanitize>
        )}
        {view === "edit" && (
          <RA.ReferenceField label="Venue" source="venueId" reference="venues">
            <RA.TextField source="name" />
          </RA.ReferenceField>
        )}
        <RA.TextInput
          source="displayName"
          helperText="This is the name of the asset template"
          validate={RA.required()}
        />
        <RA.TextInput
          source="name"
          helperText="This is the name of the asset itself, not required"
        />
        <NumberInput source="maxSeatsAvailable" />
        <RA.SelectInput
          source="venueSection"
          choices={venue?.sectionNames
            .map((obj: { field: string }) => obj.field)
            .map((choice: string) => ({
              id: choice,
              name: choice,
            }))}
        />
        <RA.SelectInput
          source="seatingType"
          choices={SeatingType}
          validate={RA.required()}
          onChange={(e) => setSeatingTypeVal(e.target.value)}
        />
        <RA.SelectInput
          source="cateringType"
          choices={CateringType}
          validate={RA.required()}
        />
        <RA.TextInput source="notes" multiline={true} minRows={8} />
        <BooleanInputs record={record} seatingType={seatingTypeVal} />
        {view === "create" && (
          <RA.NumberInput
            source="venueId"
            className={styles.displayNone}
            defaultValue={venue.id}
          />
        )}
      </RA.FormTab>
      <RA.FormTab label="Relationships">
        <RA.ReferenceField label="Venue" source="venueId" reference="venues">
          <RA.ChipField source="name" />
        </RA.ReferenceField>
        <RA.ReferenceManyField
          label="assets"
          reference="assets"
          target="assetTemplateId"
          pagination={<CustomPagination />}
          perPage={50}
        >
          <AssetsDataGrid
            showVenueData={false}
            showListingPartnerData={false}
          />
        </RA.ReferenceManyField>
      </RA.FormTab>
    </RA.TabbedForm>
  );
};

export default Form;
