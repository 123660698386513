export const convertShTzToIntlTz = (shTz: string) => {
  switch (shTz) {
    case "us_pacific":
      return "America/Los_Angeles";
    case "us_arizona":
      return "America/Phoenix";
    case "us_mountain":
      return "America/Denver";
    case "us_central":
      return "America/Chicago";
    case "us_eastern":
      return "America/New_York";
  }
};
