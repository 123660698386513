import * as RA from "react-admin";
import { useLocation } from "react-router-dom";
import {
  AccessType,
  BookingMethod,
  ListingSource,
  ListingStatus,
  CateringType,
} from "../../../utils/enums";
import { getDataFromCustomRoute } from "../../providers/DataProvider";
import useAuthentication from "../../hooks/useAuthentication";
import CustomToolBar from "../../layout/CustomToolBar";
import useStyles from "../../hooks/Styles";
import queryString from "query-string";
import HtmlSanitize from "../../common/HtmlSanitize";
import { Divider } from "@material-ui/core";
import { SyntheticEvent, useEffect, useState } from "react";
import NumberInput from "../../common/NumberInput";
import { calculatePriceFromMargin } from "../../../utils/margin";
import { useForm } from "react-final-form";
import { fetchAssetApiUrl, fetchMarginApiUrl } from "../../../utils/urls";

const Form = ({ view = "create", ...props }) => {
  const { record } = props;
  const styles = useStyles();
  const location = useLocation();
  const { user, authToken } = useAuthentication();
  const params = queryString.parse(location?.search as string);
  const paramData = params.source ? JSON.parse(params.source as string) : null;
  const accessTypeOptions = AccessType.filter((at) => at.id !== "NA");

  record.assetId = paramData?.assetId || record.assetId;
  record.eventId = paramData?.eventId || record.eventId;

  const [redirectUrl, setRedirectUrl] = useState(
    paramData?.eventId
      ? `/events/${paramData.eventId}/2`
      : record.assetId
      ? `/assets/${record.assetId}/2`
      : ""
  );
  const [assetId, setAssetId] = useState(record.assetId);
  const [asset, setAsset] = useState<null | Record<any, any>>(null);
  const [eventId, setEventId] = useState(record.eventId);
  const [showForm, setShowForm] = useState(false);
  const [isTevo, setIsTevo] = useState(
    record.listingSource === "ticket_evolution" || record.tevoId
  );
  const [accessType, setAccessType] = useState(record?.accessType || "private");
  const [bookingMethod, setBookingMethod] = useState(
    record?.bookingMethod || "instant_book"
  );
  const [wholesalePrice, setWholesalePrice] = useState<null | number>(
    record?.wholesalePrice || null
  );
  const [retailPrice, setRetailPrice] = useState<null | number>(
    record?.retailPrice || null
  );
  const [margin, setMargin] = useState<null | number>(record?.margin || null);
  const [marginOverride, setMarginOverride] = useState<boolean>(
    record?.marginOverride || false
  );

  // Redirect Url
  useEffect(() => {
    if (!redirectUrl && assetId) {
      setRedirectUrl(`/assets/${assetId}/2`);
    }
  }, [redirectUrl, assetId]);

  // Displaying Main Part of Form
  useEffect(() => {
    if (asset && eventId) {
      setShowForm(true);
    }
  }, [asset, eventId]);

  // Fetch Asset Data
  useEffect(() => {
    if (asset || !assetId || !authToken) return;

    const url = fetchAssetApiUrl(assetId);
    getDataFromCustomRoute(url, authToken, user).then((data) => {
      setAsset(data);
    });
    return;
  }, [asset, assetId, bookingMethod, authToken, user]);

  // Set isTevo
  useEffect(() => {
    if (asset && asset.listingPartnerId) {
      setIsTevo(false);
    }
  }, [asset]);

  // Set seating type
  useEffect(() => {
    if (asset) {
      if (asset.seatingType === "suite" && record.accessType === "NA") {
        setAccessType("private");
      }
      if (asset.seatingType !== "suite") {
        setAccessType("NA");
      }
    }
  }, [asset]);

  // Set margin
  useEffect(() => {
    if (!margin && asset && assetId && eventId && authToken) {
      if (bookingMethod === "confirm") {
        setMargin(asset.confirmMargin);
      } else {
        (async () => {
          const url = fetchMarginApiUrl(assetId, asset.venueId, record.eventId);
          getDataFromCustomRoute(url, authToken, user).then((data) => {
            setMargin(data);
          });
        })();
      }
    }
  }, [asset, eventId, margin, bookingMethod, authToken, user]);

  // Auto calc retail price
  useEffect(() => {
    if (wholesalePrice && margin && !marginOverride) {
      const sellsFor = Math.round(
        calculatePriceFromMargin(wholesalePrice, margin)
      );
      setRetailPrice(sellsFor);
    }
  }, [wholesalePrice, margin, marginOverride, isTevo]);

  return (
    <RA.TabbedForm
      {...props}
      toolbar={<CustomToolBar view={view} />}
      redirect={redirectUrl}
    >
      <RA.FormTab label="Properties">
        {record.assetId ? (
          <RA.ReferenceField
            label="Asset"
            source="assetId"
            reference="assets"
            link={false}
            addLabel
          >
            <RA.TextField source="name" className={styles.input} />
          </RA.ReferenceField>
        ) : (
          <RA.ReferenceInput
            label="Asset"
            source="assetId"
            reference="assets"
            onChange={setAssetId}
            validate={RA.required()}
          >
            <RA.AutocompleteInput source="name" className={styles.input} />
          </RA.ReferenceInput>
        )}
        {record.assetId && (
          <RA.ReferenceField
            label="Premium Seating Type"
            source="assetId"
            reference="assets"
            link={false}
            addLabel
          >
            <RA.TextField source="seatingType" className={styles.input} />
          </RA.ReferenceField>
        )}
        {record.eventId && (
          <RA.ReferenceField
            label="Event Name"
            source="eventId"
            reference="events"
            link={false}
            addLabel
          >
            <RA.TextField source="name" className={styles.input} />
          </RA.ReferenceField>
        )}
        {record.eventId ? (
          <RA.ReferenceField
            label="Event Date"
            source="eventId"
            reference="events"
            link={false}
            addLabel
          >
            <RA.DateField
              source="occursAt"
              locales="en-us"
              options={{ timeZone: "America/Los_Angeles" }}
              className={styles.input}
            />
          </RA.ReferenceField>
        ) : (
          <RA.ReferenceInput
            label="Event"
            source="eventId"
            reference="events"
            isRequired={true}
            validate={RA.required()}
            onChange={setEventId}
          >
            <RA.AutocompleteInput source="name" validate={RA.required()} />
          </RA.ReferenceInput>
        )}
        <HtmlSanitize>
          <Divider className={styles.divider} />
        </HtmlSanitize>

        {showForm && (
          <RA.SelectInput
            source="bookingMethod"
            choices={BookingMethod}
            validate={RA.required()}
            className={styles.input}
            initialValue={bookingMethod}
            onChange={(e) => {
              const method = e.target.value;
              setBookingMethod(method);
              method === "confirm"
                ? setMargin(asset?.confirmMargin)
                : setMargin(asset?.margin);
            }}
            disabled={bookingMethod === "ticket_evolution"}
          />
        )}
        {showForm && asset?.seatingType === "suite" ? (
          <RA.SelectInput
            source="accessType"
            label="Access Type"
            validate={RA.required()}
            choices={accessTypeOptions}
            className={styles.input}
            onChange={(e) => setAccessType(e.target.value)}
            initialValue={accessType}
          />
        ) : (
          <RA.SelectInput
            source="accessType"
            label="Access Type"
            validate={RA.required()}
            choices={AccessType}
            className={styles.displayNone}
            initialValue={accessType}
            disabled
          />
        )}
        {showForm && asset && accessType !== "private" && (
          <NumberInput
            source="seats"
            label="Seats Available"
            helperText={`how many seats out of the assets ${asset.maxSeatsAvailable} seats are available for this listing?`}
            validate={[
              RA.required(),
              RA.minValue(1),
              RA.maxValue(asset.maxSeatsAvailable),
            ]}
            className={styles.input}
          />
        )}
        {showForm && (
          <HtmlSanitize>
            <Divider className={styles.divider} />
          </HtmlSanitize>
        )}
        {showForm && (
          <RA.NumberInput
            source="margin"
            helperText={`The current margin being applied is ${margin}`}
            disabled={marginOverride}
            onChange={(e: SyntheticEvent) => {
              const target = e.target as HTMLInputElement;
              const value = parseInt(target.value);
              if (value > 0) {
                setMargin(value);
              } else {
                setMargin(null);
              }
            }}
          />
        )}
        {showForm && (
          <NumberInput
            source="wholesalePrice"
            label="Net Proceeds"
            helperText="how much money do you want to make on this listing?"
            validate={[RA.required(), RA.minValue(1)]}
            initialValue={wholesalePrice}
            onChange={(e: SyntheticEvent) => {
              const target = e.target as HTMLInputElement;
              const price = isNaN(parseInt(target.value))
                ? 0
                : parseInt(target.value);
              setWholesalePrice(price);
            }}
            className={styles.input}
            disabled={isTevo}
          />
        )}
        {showForm && (
          <RetailPriceInput
            retailPrice={retailPrice}
            marginOverride={marginOverride}
            styles={styles}
          />
        )}
        {showForm && (
          <RA.BooleanInput
            source="marginOverride"
            onChange={setMarginOverride}
          />
        )}
        {showForm && (
          <HtmlSanitize>
            <Divider className={styles.divider} />
          </HtmlSanitize>
        )}
        {showForm && (
          <RA.SelectInput
            source="listingSource"
            choices={ListingSource}
            validate={RA.required()}
            initialValue={
              view === "create" ? "suitehop_admin" : record.listingSource
            }
          />
        )}
        {showForm && (
          <RA.SelectInput
            source="status"
            choices={ListingStatus}
            validate={RA.required()}
            initialValue={
              view === "create" ? "available" : record.listingStatus
            }
          />
        )}
        {showForm && isTevo && (
          <RA.TextInput source="tevoId" label="Ticket Evolution ID" />
        )}
        {showForm && (
          <RA.SelectInput source="cateringType" choices={CateringType} />
        )}
        {showForm && (
          <RA.TextInput source="notes" multiline={true} minRows={8} />
        )}
        {showForm && (
          <RA.TextInput source="internalNotes" multiline={true} minRows={8} />
        )}
        {showForm && (
          <RA.BooleanInput source="offlineOnly" label="Offline listing" />
        )}
        {showForm && <RA.BooleanInput source="taxExempt" label="Tax exempt" />}
        {showForm && view === "edit" && (
          <RA.BooleanField source="instantDelivery" />
        )}
        {showForm && view === "edit" && (
          <RA.BooleanField source="convertedSharedToPrivate" />
        )}
      </RA.FormTab>
      <RA.FormTab label="Relationships">
        <RA.ReferenceField label="Asset" source="assetId" reference="assets">
          <RA.ChipField source="name" />
        </RA.ReferenceField>
        <RA.ReferenceField label="Event" source="eventId" reference="events">
          <RA.ChipField source="name" />
        </RA.ReferenceField>
      </RA.FormTab>
    </RA.TabbedForm>
  );
};

const RetailPriceInput = ({
  retailPrice,
  marginOverride,
  styles,
  ...props
}: {
  retailPrice: null | number;
  marginOverride: boolean;
  styles: Record<any, any>;
}) => {
  const form = useForm();
  form.change("retailPrice", retailPrice);
  return (
    <NumberInput
      source="retailPrice"
      label="Sells For"
      helperText="This is the price that is displayed to the public after commission is applied"
      validate={[RA.required(), RA.minValue(1)]}
      initialValue={retailPrice}
      disabled={!marginOverride}
      className={styles.input}
      {...props}
    />
  );
};

export default Form;
