import * as RA from "react-admin";

export default (props: RA.DatagridProps) => (
  <RA.Datagrid rowClick="edit" {...props}>
    <RA.ImageField source="imageUrl" />
    <RA.TextField source="description" />
    <RA.EditButton />
    <RA.ShowButton />
  </RA.Datagrid>
);
