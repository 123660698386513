import * as RA from "react-admin";
import CustomPagination from "../../layout/CustomPagination";
import OrdersDataGrid from "./ordersDataGrid";
import { OrderStatus, BookingMethod } from "../../../utils/enums";
import useStyles from "../../hooks/Styles";

export default (props: RA.ListProps) => {
  const styles = useStyles();
  const filters = [
    <RA.TextInput
      label="Search by order number"
      source="q"
      alwaysOn
      className={styles.search}
    />,
    <RA.SelectInput source="status" choices={OrderStatus} alwaysOn />,
    <RA.SelectInput source="type" choices={BookingMethod} alwaysOn />,
  ];

  return (
    <RA.List
      filters={filters}
      pagination={<CustomPagination />}
      perPage={50}
      sort={{ field: "createdAt", order: "DESC" }}
      {...props}
    >
      <OrdersDataGrid />
    </RA.List>
  );
};
