import * as RA from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(4),
    color: "#FFFFFF",
  },
}));

type AddNewSubRelationShipButtonType = {
  resourceName: string;
  path: string;
  venueId: string;
};
const AddNewSubRelationshipButton = ({
  resourceName,
  path,
  venueId,
}: AddNewSubRelationShipButtonType) => {
  const styles = useStyles();

  return (
    <RA.Button
      className={styles.button}
      variant="contained"
      component={RA.Link}
      to={{
        pathname: `/${path}/create/`,
        search: `venueId=${venueId}`,
      }}
      label={`Add a new ${resourceName}`}
    >
      <AddIcon />
    </RA.Button>
  );
};

export default AddNewSubRelationshipButton;
