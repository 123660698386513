import * as RA from "react-admin";
import CodeBlockField from "../../../common/CodeField";

export default (props: RA.DatagridProps) => (
  <RA.Datagrid rowClick="show" {...props}>
    <CodeBlockField source="whereQuery" />
    <CodeBlockField source="updateData" />
    <RA.ShowButton />
  </RA.Datagrid>
);
