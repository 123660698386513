import * as RA from "react-admin";
import CreateListingLink from "../../common/CreateListingLink";
import { convertShTzToIntlTz } from "../../../utils/timeZones";

const OccursAtWithVenueTimeZone = () => {
  const event = RA.useRecordContext();
  const { referenceRecord } = RA.useReference({
    reference: "venues",
    id: event.venueId,
  }) as Record<any, any>;

  if (!referenceRecord) {
    return null;
  }

  return (
    <RA.DateField
      source="occursAt"
      locales="en-us"
      options={{ timeZone: convertShTzToIntlTz(referenceRecord.timeZone) }}
    />
  );
};

const EventsDataGrid = ({
  showVenueData = true,
  showExpiredColumn = false,
}) => {
  return (
    <RA.Datagrid rowClick="edit">
      <RA.TextField source="id" />
      <RA.TextField source="name" />
      {/*// @ts-ignore*/}
      <OccursAtWithVenueTimeZone label="Occurs at" />
      <RA.DateField
        source="createdAt"
        locales="en-us"
        options={{ timeZone: "America/Los_Angeles" }}
      />
      {showExpiredColumn && <RA.TextField source="expired" />}
      {showVenueData && (
        <RA.ReferenceField
          label="Venue"
          source="venueId"
          reference="venues"
          link="show"
          sortable={false}
        >
          <RA.TextField source="name" />
        </RA.ReferenceField>
      )}
      <CreateListingLink referenceType="event" />
      <RA.EditButton />
      <RA.ShowButton />
    </RA.Datagrid>
  );
};

export default EventsDataGrid;
