import * as RA from "react-admin";
import CustomToolBar from "../../layout/CustomToolBar";
import NumberInput from "../../common/NumberInput";

const Form = ({ view = "create", ...props }) => {
  return (
    <RA.SimpleForm
      {...props}
      toolbar={<CustomToolBar view={view} />}
      redirect="list"
    >
      <NumberInput
        source="margin"
        validate={RA.required()}
        label="Default Ticket Evo margin"
      />
      <NumberInput source="privateSuiteThreshold" validate={RA.required()} />
    </RA.SimpleForm>
  );
};

export default Form;
