import * as RA from "react-admin";
import HtmlSanitize from "../../common/HtmlSanitize";
import { Category } from "../../../utils/enums";
import useStyles from "../../hooks/Styles";
import Alert from "@material-ui/lab/Alert";
import ListingsDataGrid from "../listings/listingsDataGrid";
import CustomPagination from "../../layout/CustomPagination";
import CustomToolBar from "../../layout/CustomToolBar";
import NumberInput from "../../common/NumberInput";
import ImageUploader from "../../common/ImageUploader";

const Form = ({ view = "create", ...props }) => {
  const styles = useStyles();

  return (
    <RA.TabbedForm
      {...props}
      toolbar={<CustomToolBar view={view} />}
      redirect="list"
    >
      <RA.FormTab label="Properties">
        <ImageUploader
          source="featuredImage"
          label="Featured Image"
          helperText="Landscape style image. Min 1000px wide."
        />
        <RA.BooleanInput
          source="featured"
          initialValue={view === "create" ? false : props.record.featured}
        />
        <RA.TextInput source="name" validate={RA.required()} />
        <RA.DateTimeInput
          source="occursAt"
          locales="en-us"
          validate={RA.required()}
        />
        <RA.TextInput source="slug" validate={RA.required()} />
        <NumberInput source="ticketEvoId" label="Ticket evo Id" />
        <NumberInput source="margin" />
        <NumberInput
          source="privateSuiteThreshold"
          helperText="Price that determines whether a suite is private or not coming from Tevo. Optional."
        />
        <RA.BooleanInput
          source="expired"
          initialValue={view === "create" ? false : props.record.expired}
        />
        <RA.BooleanInput
          source="highPriority"
          initialValue={view === "create" ? false : props.record.expired}
          helperText="If checked, this event will be synced with ticket evoltion every 10 minutes."
        />
        <RA.ArrayInput source="categories">
          <RA.SimpleFormIterator>
            <RA.SelectInput
              source="field"
              label="category"
              choices={Category}
            />
          </RA.SimpleFormIterator>
        </RA.ArrayInput>
      </RA.FormTab>
      <RA.FormTab label="Relationships">
        <HtmlSanitize>
          <Alert className={styles.alert} severity="info">
            Note: Event listings can not be created in this view. You must first
            create a listing and attach it to an event from the the listings
            create form.
          </Alert>
        </HtmlSanitize>
        <RA.ReferenceInput
          label="Venue"
          source="venueId"
          reference="venues"
          isRequired={true}
        >
          <RA.AutocompleteInput source="name" validate={RA.required()} />
        </RA.ReferenceInput>
        <RA.ReferenceArrayInput
          source="performerIds"
          reference="performers"
          label="Performers"
        >
          <RA.AutocompleteArrayInput source="name" />
        </RA.ReferenceArrayInput>
        {view === "edit" && (
          <RA.ReferenceManyField
            label="Listings"
            reference="listings"
            target="eventId"
            pagination={<CustomPagination />}
            perPage={50}
          >
            <ListingsDataGrid
              showEventsData={false}
              showStatusColumn={true}
              showAssetData={true}
            />
          </RA.ReferenceManyField>
        )}
      </RA.FormTab>
    </RA.TabbedForm>
  );
};

export default Form;
