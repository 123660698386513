import * as RA from "react-admin";
import CodeBlockField from "../../../common/CodeField";

export default (props: RA.ShowProps) => (
  <RA.Show {...props}>
    <RA.SimpleShowLayout>
      <CodeBlockField source="whereQuery" />
      <CodeBlockField source="updateData" />
    </RA.SimpleShowLayout>
  </RA.Show>
);
