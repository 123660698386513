import * as RA from "react-admin";
import PersistedNameContainer from "../../common/PersistedNameContainer";
import EventsDataGrid from "../events/eventsDataGrid";
import CustomPagination from "../../layout/CustomPagination";
import { boxOfficePvpUrl } from "../../../utils/urls";

const Container = (props: any) => {
  const performerLink = props.record
    ? boxOfficePvpUrl(props.record.primaryCategory, props.record.slug)
    : null;

  return (
    <PersistedNameContainer name={props.record.name} link={performerLink}>
      <PerformersShow {...props} />
    </PersistedNameContainer>
  );
};

const PerformersShow = ({ ...props }) => (
  <RA.TabbedShowLayout {...props}>
    <RA.Tab label="Properties">
      <RA.ImageField source="featuredImage" />
      <RA.TextField source="name" />
      <RA.TextField source="slug" />
      <RA.TextField source="type" />
      <RA.BooleanField source="ignore" />
      <RA.BooleanField source="featured" />
      <RA.TextField source="primaryCategory" />
      <RA.ArrayField source="categories">
        <RA.SingleFieldList linkType={false}>
          <RA.ChipField source="field" />
        </RA.SingleFieldList>
      </RA.ArrayField>
      <RA.TextField source="ticketEvoId" label="Ticket Evo Id" />
      <RA.TextField source="spotifyId" label="Spotify Id" />
      <RA.DateField
        source="createdAt"
        locales="en-us"
        options={{ timeZone: "America/Los_Angeles" }}
      />
    </RA.Tab>
    <RA.Tab label="Relationships">
      <RA.ReferenceField
        label="Home Venue"
        source="homeVenueId"
        reference="venues"
      >
        <RA.ChipField source="name" />
      </RA.ReferenceField>
      <RA.ReferenceManyField
        label="events"
        reference="events"
        target="eventsByPerformerId"
        pagination={<CustomPagination />}
        perPage={50}
      >
        <EventsDataGrid showVenueData={true} showExpiredColumn={true} />
      </RA.ReferenceManyField>
    </RA.Tab>
  </RA.TabbedShowLayout>
);

export default (props: RA.ShowProps) => (
  <RA.Show {...props}>
    <Container {...props} />
  </RA.Show>
);
