import * as RA from "react-admin";
import { BookingMethod, OrderStatus } from "../../../utils/enums";
import CustomToolBar from "../../layout/CustomToolBar";
import NumberInput from "../../common/NumberInput";

const Form = ({ view = "create", ...props }) => {
  return (
    <RA.TabbedForm
      {...props}
      toolbar={<CustomToolBar view={view} />}
      redirect="list"
    >
      <RA.FormTab label="Properties">
        <RA.SelectInput
          source="status"
          choices={OrderStatus}
          validate={RA.required()}
        />
        <RA.SelectInput
          source="type"
          choices={BookingMethod}
          validate={RA.required()}
        />
        <NumberInput source="wholesalePrice" validate={RA.required()} />
        <NumberInput source="subtotal" validate={RA.required()} />
        <NumberInput source="tax" validate={RA.required()} />
        <NumberInput source="salePrice" validate={RA.required()} />
        <NumberInput source="marginApplied" />
        <NumberInput source="numberTickets" validate={RA.required()} />
        <RA.TextInput source="tevoOrderId" />
        <RA.TextInput source="lastFourCC" label="Last four of credit card" />
        <RA.TextInput source="transactionId" />
        <RA.TextInput source="fraudScore" />
        <RA.TextInput source="fraudRisk" />
        {view === "edit" && (
          <RA.ReferenceField
            label="Offline Sale"
            source="listingId"
            reference="listings"
            link={false}
          >
            <RA.BooleanField source="offlineOnly" />
          </RA.ReferenceField>
        )}
      </RA.FormTab>
      <RA.FormTab label="Relationships">
        <RA.ReferenceInput
          label="Customer"
          source="customerId"
          reference="customers"
          validate={RA.required()}
        >
          <RA.AutocompleteInput source="email" optionText="email" />
        </RA.ReferenceInput>
        <RA.ReferenceInput
          label="Listing ID"
          source="listingId"
          reference="listings"
        >
          <NumberInput source="id" />
        </RA.ReferenceInput>
      </RA.FormTab>
    </RA.TabbedForm>
  );
};

export default Form;
