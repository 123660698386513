import * as RA from "react-admin";
import MarketForm from "./_Form";
import PersistedNameContainer from "../../common/PersistedNameContainer";

export default (props: RA.EditProps) => (
  <RA.Edit {...props}>
    <PersistedNameContainer>
      <MarketForm view="edit" />
    </PersistedNameContainer>
  </RA.Edit>
);
