import React from "react";
import * as RA from "react-admin";
import { Event, Visibility } from "@material-ui/icons";
import useAuthentication from "../../hooks/useAuthentication";
import useStyles from "../../hooks/Styles";
import { Country, TimeZone, VenueType } from "../../../utils/enums";
import { getDataFromCustomRoute } from "../../providers/DataProvider";
import HtmlSanitize from "../../common/HtmlSanitize";
import TevoMappingsList from "./tevoMappings/list";
import VenueMapsList from "./maps/list";
import VenueVirtualMapsList from "./virtualMaps/list";
import VenuePhotosList from "./photos/list";
import AssetPhotosList from "../assetPhotos/list";
import AssetTemplatesList from "./assetTemplates/list";
import AssetsDataGrid from "../assets/assetsDataGrid";
import EventsDataGrid from "../events/eventsDataGrid";
import ListingRulesList from "./listingRules/list";
import Alert from "@material-ui/lab/Alert";
import PersistedNameContainer from "../../common/PersistedNameContainer";
import AddNewSubRelationshipButton from "./components/AddNewSubResourceButton";
import CustomPagination from "../../layout/CustomPagination";
import CustomToolBar from "../../layout/CustomToolBar";

import NumberInput from "../../common/NumberInput";
import ImageUploader from "../../common/ImageUploader";
import {
  boxOfficeVenueUrl,
  triggerTevoVenueAssetsRulesRun,
  triggerTevoVenueSyncUrl,
} from "../../../utils/urls";

const Container = (props: any) => {
  // TODO: Update to account for amphitheatres as well
  const venueLink = props.record ? boxOfficeVenueUrl(props.record.slug) : null;

  return (
    <PersistedNameContainer name={props.record.name} link={venueLink}>
      <VenueEdit {...props} />
    </PersistedNameContainer>
  );
};

const EditActions = ({ venueId }: { venueId: string | undefined }) => {
  const { user, authToken } = useAuthentication();

  if (!venueId || !authToken) {
    return null;
  }

  return (
    <RA.TopToolbar>
      <RA.Button
        onClick={() =>
          window.location.assign(
            `${process.env.REACT_APP_APPLICATION_URL}/#/venues/${venueId}/show`
          )
        }
        label={"Show"}
      >
        <Visibility />
      </RA.Button>
      <RA.Button
        onClick={async () => {
          const url = triggerTevoVenueAssetsRulesRun(venueId);
          await getDataFromCustomRoute(url, authToken, user);
          alert("Sync started");
        }}
        label="Apply Rules to All Assets"
      >
        <Event />
      </RA.Button>
      <RA.Button
        onClick={async () => {
          const url = triggerTevoVenueSyncUrl(venueId);
          await getDataFromCustomRoute(url, authToken, user);
          alert("Sync with tevo scheduled");
        }}
        label="Schedule Sync with Tevo"
      >
        <Event />
      </RA.Button>
    </RA.TopToolbar>
  );
};

const VenueEdit = ({ ...props }) => {
  const styles = useStyles();
  const { record } = props;

  return (
    <RA.TabbedForm
      {...props}
      toolbar={<CustomToolBar view="edit" noDelete={true} />}
      redirect="list"
    >
      <RA.FormTab label="Properties">
        <RA.TextInput source="name" validate={RA.required()} />
        <RA.SelectInput
          source="venueType"
          choices={VenueType}
          validate={RA.required()}
        />
        <NumberInput
          source="ticketEvoId"
          label="Ticket Evo Id"
          validate={RA.required()}
        />
        <NumberInput source="margin" />
        <RA.TextInput source="slug" validate={RA.required()} />
        <RA.SelectInput
          source="country"
          validate={RA.required()}
          choices={Country}
        />
        <RA.NumberInput source="lat" validate={RA.required()} />
        <RA.NumberInput source="long" validate={RA.required()} />
        <RA.SelectInput
          source="timeZone"
          choices={TimeZone}
          validate={RA.required()}
        />
        <NumberInput
          source="privateSuiteThreshold"
          helperText="Price that determines whether a suite is private or not coming from Tevo. Optional."
        />
        <RA.TextInput source="ticketPortalUrl" />
        <RA.BooleanInput
          source="syncExistingListings"
          label="Include in existing listings sync"
        />
        <RA.ArrayInput source="sectionNames">
          <RA.SimpleFormIterator>
            <RA.TextInput source="field" label="" />
          </RA.SimpleFormIterator>
        </RA.ArrayInput>
        <RA.ArrayInput source="synonyms">
          <RA.SimpleFormIterator>
            <RA.TextInput source="field" label="" />
          </RA.SimpleFormIterator>
        </RA.ArrayInput>
      </RA.FormTab>
      <RA.FormTab label="Mappings">
        <RA.ArrayInput source="listingTerms">
          <RA.SimpleFormIterator>
            <RA.TextInput source="field" label="Listing term item" />
          </RA.SimpleFormIterator>
        </RA.ArrayInput>
        <RA.ArrayInput source="listingExclusions">
          <RA.SimpleFormIterator>
            <RA.TextInput source="field" label="Listing exclusion item" />
          </RA.SimpleFormIterator>
        </RA.ArrayInput>
        <RA.ReferenceManyField
          label="Mappings"
          reference="ticketEvoMappings"
          target="venueId"
          pagination={<CustomPagination />}
          perPage={50}
        >
          <TevoMappingsList />
        </RA.ReferenceManyField>
      </RA.FormTab>
      <RA.FormTab label="Maps">
        {/*2D Maps*/}
        <RA.ReferenceManyField
          label="2D Venue Maps"
          reference="venueMaps"
          target="venueId"
          pagination={<CustomPagination />}
          perPage={50}
        >
          <VenueMapsList />
        </RA.ReferenceManyField>
        <AddNewSubRelationshipButton
          venueId={record.id}
          path="venueMaps"
          resourceName="venue map"
        />
        {/*3D Maps*/}
        <RA.ReferenceManyField
          label="3D Venue Maps"
          reference="venueVirtualMaps"
          target="venueId"
          pagination={<CustomPagination />}
          perPage={50}
        >
          <VenueVirtualMapsList />
        </RA.ReferenceManyField>
        <AddNewSubRelationshipButton
          venueId={record.id}
          path="venueVirtualMaps"
          resourceName="venue 3D map"
        />
      </RA.FormTab>
      <RA.FormTab label="Photos">
        <ImageUploader
          source="featuredImage"
          label="Featured Image"
          helperText="Hard Landscape Style Image. Min 1500px wide."
        />
        <RA.ReferenceManyField
          label="Venue Photos"
          reference="venuePhotos"
          target="venueId"
          pagination={<CustomPagination />}
          perPage={50}
        >
          <VenuePhotosList />
        </RA.ReferenceManyField>
        <AddNewSubRelationshipButton
          venueId={record.id}
          path="venuePhotos"
          resourceName="venue photo"
        />
        <RA.ReferenceManyField
          label="Representative Asset Photos"
          reference="assetPhotos"
          target="venueId"
          pagination={<CustomPagination />}
          perPage={50}
        >
          <AssetPhotosList />
        </RA.ReferenceManyField>
        <AddNewSubRelationshipButton
          venueId={record.id}
          path="assetPhotos"
          resourceName="asset photo"
        />
      </RA.FormTab>
      <RA.FormTab label="Asset Management">
        <RA.ReferenceManyField
          label="Venue Asset Templates"
          reference="assetTemplates"
          target="venueId"
          pagination={<CustomPagination />}
          perPage={50}
        >
          <AssetTemplatesList />
        </RA.ReferenceManyField>
        <AddNewSubRelationshipButton
          venueId={record.id}
          path="assetTemplates"
          resourceName="asset template"
        />
        <RA.ReferenceManyField
          label="Venue Listing Rules"
          reference="listingRules"
          target="listingRulesByVenueId"
          pagination={<CustomPagination />}
          perPage={50}
        >
          <ListingRulesList />
        </RA.ReferenceManyField>
      </RA.FormTab>
      <RA.FormTab label="Relationships">
        <HtmlSanitize>
          <Alert className={styles.alert} severity="info">
            Note: You can not create home teams, assets,or listings for this
            venue from this view. You must first create the resource and then
            attach it to the venue for the connection to be made.
          </Alert>
        </HtmlSanitize>
        <RA.ReferenceManyField
          label="Home Teams"
          reference="performers"
          target="homeVenueId"
        >
          <RA.SingleFieldList>
            <RA.ChipField source="name" className={styles.marginBottom} />
          </RA.SingleFieldList>
        </RA.ReferenceManyField>
        <RA.ReferenceManyField
          label="Assets"
          reference="assets"
          target="venueId"
          pagination={<CustomPagination />}
          perPage={50}
        >
          <AssetsDataGrid showVenueData={false} />
        </RA.ReferenceManyField>
        <RA.ReferenceManyField
          label="Events"
          reference="events"
          target="venueId"
          pagination={<CustomPagination />}
          perPage={50}
        >
          <EventsDataGrid showVenueData={false} showExpiredColumn={true} />
        </RA.ReferenceManyField>
      </RA.FormTab>
    </RA.TabbedForm>
  );
};

export default (props: RA.EditProps) => (
  <RA.Edit {...props} actions={<EditActions venueId={props.id} />}>
    <Container {...props} />
  </RA.Edit>
);
