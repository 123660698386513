// @ts-nocheck
import React, { useState, useEffect } from "react";
import useStyles from "../hooks/Styles";
import Typography from "@material-ui/core/Typography";
import { useForm } from "react-final-form";
import HtmlSanitize from "./HtmlSanitize";

const ThreeDView = ({ maps, virtualMapSection }) => {
  const form = useForm();
  const styles = useStyles();
  const defaultMap =
    maps.find((m: Record<any, any>) => m.category === "default") || maps[0];
  const MAP_ID = defaultMap.seatSelectionMapId; // 2D Map, same across all views
  const [scriptLoaded, setScriptLoaded] = useState(!!(window && window.DVM));
  const [twoDViewer, set2DViewer] = useState(null);
  const [threeDViewer, set3DViewer] = useState(null);
  const [currentMapCategory, setCurrentMapCategory] = useState(
    defaultMap.category
  );
  const [currentMapView, setCurrentMapView] = useState(defaultMap.viewerId);
  const [currentSection, setCurrentSection] = useState(
    virtualMapSection || null
  );

  const ensureScriptHasLoaded = () => {
    const checkForDvmScript = setInterval(() => {
      if (!!window.DVM) {
        setScriptLoaded(true);
        clearInterval(checkForDvmScript);
      }
    }, 400);
  };

  const load2dViewer = async () => {
    const twoDViewer = await window.DVM.loadModule("map_viewer", {
      container: "dvm-2d-container",
      callbacks: {
        click: (obj: Record<any, any>) => {
          const node = obj.nodes[0];
          if (node) {
            setCurrentSection(node.id);
          }
        },
      },
    });

    await set2DViewer(twoDViewer);
  };

  const load3dViewer = async () => {
    const threeDViewer = await window.DVM.loadModule("3d_viewer", {
      container: "dvm-3d-container",
    });
    await set3DViewer(threeDViewer);
  };

  // Initialize the viewers once the scripts have loaded
  useEffect(() => {
    if (scriptLoaded) {
      (async () => {
        try {
          await load2dViewer();
          await load3dViewer();
        } catch (err) {
          console.log("Viewer Load Error:", err);
        }
      })();
    } else {
      ensureScriptHasLoaded();
    }
  }, [scriptLoaded]);

  // Handle map state
  useEffect(() => {
    (async () => {
      if (twoDViewer) {
        await twoDViewer.loadMap({
          venue_id: currentMapView,
          map_id: MAP_ID,
        });

        const sections = twoDViewer.getNodesByType("section");
        twoDViewer.setAvailability("section", sections);

        if (currentSection) {
          twoDViewer.unselectAll();
          twoDViewer.select(currentSection);
          form.change("virtualMapSection", currentSection);
        }
      }

      if (threeDViewer) {
        await threeDViewer.loadView3d({
          venue_id: currentMapView,
          view_id: currentSection,
        });

        twoDViewer.unselectAll();
        twoDViewer.select(currentSection);
        form.change("virtualMapSection", currentSection);
      }
    })();
  }, [twoDViewer, threeDViewer, currentSection, currentMapView]);

  return (
    <>
      <HtmlSanitize>
        <div className={styles.threeDViewerContainer}>
          <Typography variant="h5" className={styles.header}>
            3D Map:
          </Typography>
          <select
            name="venue3DMaps"
            id="venue3DMaps"
            className={styles.marginBottom}
            onChange={(e) => {
              const updateMap = maps.find(
                (map: Record<any, any>) => map.id === parseInt(e.target.value)
              );
              setCurrentMapCategory(updateMap.category);
              setCurrentMapView(updateMap.viewerId);
            }}
            defaultValue={currentMapCategory}
          >
            {maps.map((map: Record<any, any>, index: number) => (
              <option key={index} value={map.id}>
                {map.performerSlug || map.category}
              </option>
            ))}
          </select>
          <div className={styles.threeDViewerPlugin} id="dvm-2d-container" />
          <div className={styles.threeDViewerPlugin} id="dvm-3d-container" />
        </div>
      </HtmlSanitize>
    </>
  );
};

declare global {
  interface Window {
    DVM?: any;
  }
}

export default ThreeDView;
