import * as RA from "react-admin";

export default (props: RA.DatagridProps) => (
  <RA.Datagrid rowClick="edit" {...props}>
    <RA.TextField source="section" />
    <RA.TextField source="row" />
    <RA.ReferenceField
      label="Asset"
      source="assetId"
      reference="assets"
      link="show"
      sortable={false}
    >
      <RA.TextField source="name" />
    </RA.ReferenceField>
    <RA.ShowButton />
    <RA.DeleteButton />
  </RA.Datagrid>
);
