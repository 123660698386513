import * as RA from "react-admin";
import PersistedNameContainer from "../../common/PersistedNameContainer";

export default (props: RA.ShowProps) => (
  <RA.Show {...props}>
    <Container {...props} />
  </RA.Show>
);

const Container = ({ ...props }) => (
  <PersistedNameContainer name={`Listing partner: ${props.record.email}`}>
    <ListingPartnerShow {...props} />
  </PersistedNameContainer>
);

// TODO: Email Preferences and Settings should be selects
const ListingPartnerShow = ({ ...props }) => (
  <RA.TabbedShowLayout {...props}>
    <RA.Tab label="Properties">
      <RA.TextField source="email" />
      <RA.TextField source="firstName" />
      <RA.TextField source="lastName" />
      <RA.TextField source="company" />
      <RA.TextField source="phone" />
      <RA.TextField source="address" />
      <RA.TextField source="city" />
      <RA.TextField source="state" />
      <RA.TextField source="zip" />
      <RA.TextField source="emailPreference" />
      <RA.TextField source="settings" />
      <RA.BooleanField source="receiveNotifications" />
    </RA.Tab>
    <RA.Tab label="Relationships">
      <RA.ReferenceManyField
        label="Assets"
        reference="assets"
        target="listingPartnerId"
      >
        <RA.SingleFieldList>
          <RA.ChipField source="name" />
        </RA.SingleFieldList>
      </RA.ReferenceManyField>
    </RA.Tab>
  </RA.TabbedShowLayout>
);
