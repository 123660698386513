import * as RA from "react-admin";

export default (props: RA.DatagridProps) => (
  <RA.Datagrid rowClick="edit" {...props}>
    <RA.TextField source="id" />
    <RA.TextField source="displayName" />
    <RA.TextField source="name" />
    <RA.TextField source="maxSeatsAvailable" />
    <RA.TextField source="venueSection" />
    <RA.TextField source="seatingType" />
    <RA.TextField source="cateringType" />
    <RA.TextField source="notes" />
    <RA.EditButton />
    <RA.ShowButton />
  </RA.Datagrid>
);
