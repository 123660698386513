import * as RA from "react-admin";
import { SeatingType } from "../../../utils/enums";

export default (props: RA.DatagridProps) => (
  <RA.Datagrid rowClick="edit" {...props}>
    <RA.ImageField source="imageUrl" />
    <RA.SelectField source="seatingType" choices={SeatingType} />
    <RA.TextField source="venueSection" />
    <RA.EditButton />
    <RA.ShowButton />
  </RA.Datagrid>
);
