import * as RA from "react-admin";
import HtmlSanitize from "../../common/HtmlSanitize";
import useStyles from "../../hooks/Styles";
import Alert from "@material-ui/lab/Alert";
import AssetsDataGrid from "../assets/assetsDataGrid";
import CustomPagination from "../../layout/CustomPagination";
import CustomToolBar from "../../layout/CustomToolBar";
import NumberInput from "../../common/NumberInput";

const Form = ({ view = "create", ...props }) => {
  const styles = useStyles();

  return (
    <RA.TabbedForm
      {...props}
      toolbar={<CustomToolBar view={view} />}
      redirect="list"
    >
      <RA.FormTab label="Properties">
        <RA.TextInput source="email" validate={RA.required()} />
        <RA.TextInput source="firstName" />
        <RA.TextInput source="lastName" />
        <RA.TextInput source="company" />
        <RA.TextInput source="phone" />
        <RA.TextInput source="address" />
        <RA.TextInput source="city" />
        <RA.TextInput source="state" />
        <NumberInput source="zip" />
        <RA.TextInput source="emailPreference" />
        <RA.TextInput source="settings" />
        <RA.BooleanInput
          source="receiveNotifications"
          helperText="Opts LP into receiving weekly email"
        />
      </RA.FormTab>
      <RA.FormTab label="Relationships">
        <HtmlSanitize>
          <Alert className={styles.alert} severity="info">
            Note: Listing Partner Assets can not be created in this view. You
            must first create the asset and then attach it to a listing partner
            for it to show up here.
          </Alert>
        </HtmlSanitize>
        {view === "edit" && (
          <RA.ReferenceManyField
            label="Assets"
            reference="assets"
            target="listingPartnerId"
            pagination={<CustomPagination />}
            perPage={50}
          >
            <AssetsDataGrid showListingPartnerData={false} />
          </RA.ReferenceManyField>
        )}
      </RA.FormTab>
    </RA.TabbedForm>
  );
};

export default Form;
