import React from "react";
import ReactDOM from "react-dom";
import App from "./components/App";

ReactDOM.render(
  <React.StrictMode>
    <script
      id="dvm-script"
      src="https://tk3d.tk3dapi.com/dvm/v1/lib/stable/dvm.js"
      async
    />
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
