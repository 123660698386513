// @ts-nocheck
// Note: TS enabled for this file is breaking Vercel builds, even though it compiles locally.
import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
const useAuthentication = () => {
  const { getAccessTokenSilently, user } = useAuth0();
  const [authToken, setAuthToken] = useState<string | null>(null);
  const audience = process.env.REACT_APP_AUTH0_AUDIENCE;
  const redirect_uri = process.env.REACT_APP_AUTH0_REDIRECT_URI;

  if (!audience) {
    throw new Error("REACT_APP_AUTH0_AUDIENCE is not defined");
  }

  if (!redirect_uri) {
    throw new Error("REACT_APP_AUTH0_REDIRECT_URI is not defined");
  }

  useEffect(() => {
    (async () => {
      const token = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE as string,
        redirect_uri: process.env.REACT_APP_AUTH0_REDIRECT_URI as string,
      });
      setAuthToken(token);
    })();
  }, [getAccessTokenSilently]);

  return {
    authToken,
    user,
  };
};

export default useAuthentication;
