import * as RA from "react-admin";
import GlobalSettingsForm from "./_Form";
import PersistedNameContainer from "../../common/PersistedNameContainer";

export default (props: RA.EditProps) => (
  <RA.Edit {...props}>
    <PersistedNameContainer name="Edit Global Settings">
      <GlobalSettingsForm view="edit" />
    </PersistedNameContainer>
  </RA.Edit>
);
