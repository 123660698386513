import * as RA from "react-admin";
import React, { SyntheticEvent } from "react";

const NumberInput = (props: Record<any, any>) => {
  const numberInputOnWheelPreventChange = (e: SyntheticEvent) => {
    const target = e.target as HTMLElement;
    // Prevent the input value change
    target.blur();
    // Prevent the page/container scrolling
    e.stopPropagation();
    // Refocus immediately, on the next tick (after the current function is done)
    setTimeout(() => {
      target.focus();
    }, 0);
  };

  return (
    // @ts-ignore
    <RA.NumberInput onWheel={numberInputOnWheelPreventChange} {...props} />
  );
};

export default NumberInput;
