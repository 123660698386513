import * as RA from "react-admin";
import { SeatingType } from "../../../utils/enums";

export default (props: RA.ShowProps) => (
  <RA.Show {...props}>
    <RA.SimpleShowLayout>
      <RA.ReferenceField label="Venue" source="venueId" reference="venues">
        <RA.TextField source="name" />
      </RA.ReferenceField>
      <RA.ImageField source="imageUrl" />
      <RA.SelectField source="seatingType" choices={SeatingType} />
      <RA.TextField source="venueSection" />
      <RA.TextField source="description" />
    </RA.SimpleShowLayout>
  </RA.Show>
);
