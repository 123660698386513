import * as RA from "react-admin";
import PerformerForm from "./_Form";
import PersistedNameContainer from "../../common/PersistedNameContainer";
import { boxOfficePvpUrl } from "../../../utils/urls";

const Container = (props: any) => {
  const performerLink = props.record
    ? boxOfficePvpUrl(props.record.primaryCategory, props.record.slug)
    : null;

  return (
    <PersistedNameContainer name={props.record.name} link={performerLink}>
      <PerformerForm view="edit" {...props} />
    </PersistedNameContainer>
  );
};

export default (props: RA.EditProps) => (
  <RA.Edit {...props}>
    <Container {...props} />
  </RA.Edit>
);
