import * as RA from "react-admin";

export default (props: RA.ShowProps) => (
  <RA.Show {...props}>
    <RA.SimpleShowLayout>
      <RA.ReferenceField label="Venue" source="venueId" reference="venues">
        <RA.TextField source="name" />
      </RA.ReferenceField>
      <RA.TextField source="category" />
      <RA.ImageField source="imageUrl" />
    </RA.SimpleShowLayout>
  </RA.Show>
);
