import * as RA from "react-admin";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { createTheme } from "@material-ui/core/styles";
import * as Sentry from "@sentry/react";
import { isDev } from "../utils/environment";
import CustomLayout from "./layout/CustomLayout";
import DashboardPage from "./layout/DashboardPage";
import DataProvider from "./providers/DataProvider";
import useAuthentication from "./hooks/useAuthentication";
import Assets from "./resources/assets";
import Events from "./resources/events";
import Listings from "./resources/listings";
import Performers from "./resources/performers";
import Markets from "./resources/markets";
import ListingPartners from "./resources/listingPartners";
import Customers from "./resources/customers";
import Orders from "./resources/orders";
import Venues from "./resources/venues";
import VenueMaps from "./resources/venues/maps";
import VenueVirtualMaps from "./resources/venues/virtualMaps";
import VenuePhotos from "./resources/venues/photos";
import VenueTevoMappings from "./resources/venues/tevoMappings";
import VenueAssetTemplates from "./resources/venues/assetTemplates";
import VenueListingRules from "./resources/venues/listingRules";
import AssetPhotos from "./resources/assetPhotos";
import GlobalSettings from "./resources/globalSettings";

const Dashboard = () => {
  const { user, authToken } = useAuthentication();

  if (!authToken) {
    return <div>Loading...</div>;
  }

  return (
    <RA.Admin
      theme={theme}
      dataProvider={DataProvider(authToken, user?.sub, user?.email)}
      layout={CustomLayout}
      dashboard={DashboardPage}
    >
      <RA.Resource name="venues" {...Venues} />
      <RA.Resource name="assets" {...Assets} />
      <RA.Resource name="events" {...Events} />
      <RA.Resource name="listings" {...Listings} />
      <RA.Resource name="performers" {...Performers} />
      <RA.Resource
        name="listingPartners"
        {...ListingPartners}
        options={{ label: "Listing Partners" }}
      />
      <RA.Resource name="markets" {...Markets} />
      <RA.Resource name="orders" {...Orders} />
      <RA.Resource name="customers" {...Customers} />
      <RA.Resource
        name="globalSettings"
        {...GlobalSettings}
        options={{ label: "Global Settings" }}
      />
      <RA.Resource name="venueMaps" {...VenueMaps} />
      <RA.Resource name="venuePhotos" {...VenuePhotos} />
      <RA.Resource name="ticketEvoMappings" {...VenueTevoMappings} />
      <RA.Resource name="assetTemplates" {...VenueAssetTemplates} />
      <RA.Resource name="venueVirtualMaps" {...VenueVirtualMaps} />
      <RA.Resource name="assetPhotos" {...AssetPhotos} />
      <RA.Resource name="listingRules" {...VenueListingRules} />
    </RA.Admin>
  );
};

// Suitehop Colors
const theme = createTheme({
  palette: {
    primary: {
      main: "#2B8DBE",
    },
    secondary: {
      main:
        process.env.REACT_APP_DATA_PROVIDER_URI === "https://api.suitehop.com"
          ? "#000000"
          : "#FF0000",
      contrastText: "#FFFFFF",
    },
    error: {
      main: "#c64c38",
    },
  },
  overrides: {
    // @ts-ignore
    RaFormInput: {
      input: {
        maxWidth: "500px",
        width: "100%",
      },
    },
  },
});

Sentry.init({
  dsn: "https://741e0ea04cc04f0ba1935fe2587dd42d@o1080546.ingest.us.sentry.io/6086513",
  tracesSampleRate: 0,
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 0,
});

export default isDev ? Dashboard : withAuthenticationRequired(Dashboard);
