import * as RA from "react-admin";
import CustomersForm from "./_Form";
import { Visibility, Mail } from "@material-ui/icons";
import PersistedNameContainer from "../../common/PersistedNameContainer";
import useAuthentication from "../../hooks/useAuthentication";
import { getDataFromCustomRoute } from "../../providers/DataProvider";
import {
  boxOfficeOrderReceiptPageUrl,
  triggerMailerCustomerEmailUrl,
  triggerMailerLpEmailUrl,
} from "../../../utils/urls";
import React from "react";

export default (props: RA.EditProps) => (
  <RA.Edit {...props} actions={<EditActions orderId={props.id} />}>
    <Container {...props} />
  </RA.Edit>
);

const Container = (props: any) => {
  const orderNumber = props.record.number;
  const receiptLink = boxOfficeOrderReceiptPageUrl(orderNumber);

  return (
    <PersistedNameContainer
      name={`Order Number: ${orderNumber}`}
      receiptLink={receiptLink}
    >
      <CustomersForm view="edit" {...props} />
    </PersistedNameContainer>
  );
};

const EditActions = ({ orderId }: { orderId: string | undefined }) => {
  if (!orderId) {
    return null;
  }

  const { user, authToken } = useAuthentication();
  const order = RA.useGetOne("orders", orderId).data as RA.Record;

  if (!order) {
    return null;
  }

  const canSendEmail =
    order.status === "approved" ||
    order.status === "paid" ||
    order.status === "delivered";

  if (!order || !authToken) {
    return null;
  }

  return (
    <RA.TopToolbar>
      <RA.Button
        onClick={() =>
          window.location.assign(
            `${process.env.REACT_APP_APPLICATION_URL}/#/orders/${orderId}/show`
          )
        }
        label={"Show"}
      >
        <Visibility />
      </RA.Button>
      {canSendEmail && (
        <RA.Button
          onClick={() => {
            const url = triggerMailerCustomerEmailUrl(orderId);
            getDataFromCustomRoute(url, authToken, user).then((res) => {
              if (res.ok) {
                alert("Email sent");
              } else {
                alert("Failure, something went wrong");
              }
            });
          }}
          label="Resend sale success email"
        >
          <Mail />
        </RA.Button>
      )}
      {canSendEmail && (
        <RA.Button
          onClick={() => {
            const url = triggerMailerLpEmailUrl(orderId);
            getDataFromCustomRoute(url, authToken, user).then((res) => {
              if (res.ok) {
                alert("Email sent");
              } else {
                alert("Failure, something went wrong");
              }
            });
          }}
          label="Resend LP success email"
        >
          <Mail />
        </RA.Button>
      )}
    </RA.TopToolbar>
  );
};
