import * as RA from "react-admin";
import CustomToolBar from "../../layout/CustomToolBar";
import NumberInput from "../../common/NumberInput";
import ImageUploader from "../../common/ImageUploader";

const Form = ({ view = "create", ...props }) => {
  return (
    <RA.SimpleForm
      {...props}
      toolbar={<CustomToolBar view={view} />}
      redirect="list"
    >
      <ImageUploader
        source="featuredImage"
        label="Featured Image"
        helperText="Landscape image. Min 1000px wide."
      />
      <RA.TextInput source="name" validate={RA.required()} />
      <RA.TextInput source="slug" validate={RA.required()} />
      <NumberInput source="lat" validate={RA.required()} />
      <NumberInput source="long" validate={RA.required()} />
      <RA.ReferenceArrayInput
        source="venueIds"
        reference="venues"
        label="Associated Venues"
      >
        <RA.AutocompleteArrayInput source="name" />
      </RA.ReferenceArrayInput>
    </RA.SimpleForm>
  );
};

export default Form;
