import * as RA from "react-admin";
import Alert from "@material-ui/lab/Alert";
import HtmlSanitize from "../../common/HtmlSanitize";
import useStyles from "../../hooks/Styles";
import CustomPagination from "../../layout/CustomPagination";
import OrdersDataGrid from "../orders/ordersDataGrid";
import CustomToolBar from "../../layout/CustomToolBar";

const Form = ({ view = "create", ...props }) => {
  const styles = useStyles();

  return (
    <RA.TabbedForm
      {...props}
      toolbar={<CustomToolBar view={view} />}
      redirect="list"
    >
      <RA.FormTab label="Properties">
        <RA.TextInput source="email" validate={RA.required()} />
        <RA.TextInput source="firstName" />
        <RA.TextInput source="lastName" />
        <RA.TextInput source="phone" />
        <RA.TextInput source="company" />
        <RA.TextInput source="address" />
        <RA.TextInput source="city" />
        <RA.TextInput source="state" />
        <RA.TextInput source="zip" />
      </RA.FormTab>
      <RA.FormTab label="Relationships">
        <HtmlSanitize>
          <Alert className={styles.alert} severity="info">
            Note: An order must be assigned to a user at the order level.
          </Alert>
        </HtmlSanitize>
        <RA.ReferenceManyField
          label="Orders"
          reference="orders"
          target="customerId"
          pagination={<CustomPagination />}
          perPage={50}
        >
          <OrdersDataGrid />
        </RA.ReferenceManyField>
      </RA.FormTab>
    </RA.TabbedForm>
  );
};

export default Form;
