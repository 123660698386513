import * as RA from "react-admin";
import IconEvent from "@material-ui/icons/Event";
import useAuthentication from "../../hooks/useAuthentication";
import { getDataFromCustomRoute } from "../../providers/DataProvider";
import ListingsDataGrid from "./listingsDataGrid";
import CustomPagination from "../../layout/CustomPagination";
import { ListingStatus, BookingMethod } from "../../../utils/enums";
import useStyles from "../../hooks/Styles";
import { triggerMarginSyncUrl } from "../../../utils/urls";

const ListActions = () => {
  const { user, authToken } = useAuthentication();

  if (!authToken) {
    return null;
  }

  return (
    <RA.TopToolbar>
      <RA.Button
        onClick={async () => {
          const url = triggerMarginSyncUrl();
          getDataFromCustomRoute(url, authToken, user).then(() =>
            console.log("sync finished")
          );
          alert(
            "Margin sync has started, this can up to ten minutes to take effect"
          );
        }}
        label="Schedule margin sync"
      >
        <IconEvent />
      </RA.Button>
      <RA.ExportButton />
    </RA.TopToolbar>
  );
};

const ListingList = (props: RA.ListProps) => {
  const styles = useStyles();
  const filters = [
    <RA.TextInput
      label="Search by listing id"
      source="q"
      alwaysOn
      className={styles.search}
    />,
    <RA.SelectInput source="status" choices={ListingStatus} alwaysOn />,
    <RA.SelectInput source="bookingMethod" choices={BookingMethod} alwaysOn />,
    <RA.BooleanInput source="offlineOnly" label="Offline Listings" alwaysOn />,
    <RA.BooleanInput
      source="instantDelivery"
      label="Instant Delivery"
      alwaysOn
    />,
  ];
  return (
    <RA.List
      pagination={<CustomPagination />}
      perPage={50}
      hasCreate
      empty={false}
      actions={<ListActions />}
      filters={filters}
      filterDefaultValues={{ status: "available" }}
      sort={{ field: "eventId", order: "ASC" }}
      {...props}
    >
      <ListingsDataGrid />
    </RA.List>
  );
};

export default ListingList;
