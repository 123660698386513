import list from "./list";
import create from "./create";
import edit from "./edit";
import show from "./show";

export default {
  list,
  create,
  edit,
  show,
};
