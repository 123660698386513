import * as RA from "react-admin";
import { Category } from "../../../../utils/enums";
import useStyles from "../../../hooks/Styles";
import { parse } from "query-string";
import HtmlSanitize from "../../../common/HtmlSanitize";
import Typography from "@material-ui/core/Typography";
import CustomToolBar from "../../../layout/CustomToolBar";

const Form = ({ view = "create", ...props }) => {
  const styles = useStyles();
  const { record } = props;
  const params = parse(`?${location.hash.split("?")[1]}`);
  const venueId = record.venueId
    ? record.venueId
    : parseInt(params.venueId as string);
  const venue = RA.useGetOne("venues", venueId).data as RA.Record;

  if (!venue) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <RA.SimpleForm
      {...props}
      initialValues={{ venueId }}
      toolbar={
        <CustomToolBar
          view={view}
          deleteRedirectPath={`/venues/${venueId}/2`}
        />
      }
      redirect={`/venues/${venueId}/2`}
    >
      {view === "create" && (
        <HtmlSanitize>
          <Typography variant="h5" className={styles.header}>
            New 3D Map: {venue.name}
          </Typography>
        </HtmlSanitize>
      )}
      {view === "edit" && (
        <RA.ReferenceField label="Venue" source="venueId" reference="venues">
          <RA.TextField source="name" />
        </RA.ReferenceField>
      )}
      <RA.TextInput
        source="viewerId"
        validate={RA.required()}
        label="Venue 3D Viewer Id"
        helperText="Each unique view has a unique ID. Crypto concert view will have a different id than Crypto lakers view for example."
      />
      <RA.TextInput
        source="seatSelectionMapId"
        validate={RA.required()}
        label="Seat selection map Id"
        helperText="ID of the 2d map to be able to pick the section for a given asset. Should be the same for the venue regardless of view"
      />
      <RA.SelectInput
        source="category"
        choices={Category}
        validate={RA.required()}
      />
      <RA.TextInput
        source="performerSlug"
        helperText="Not required. If a performer specific 3d map (example: lakers), then you must put the performers slug here (example: los-angeles-lakers)"
      />
    </RA.SimpleForm>
  );
};

export default Form;
