import * as RA from "react-admin";
import useStyles from "../../hooks/Styles";
import CustomPagination from "../../layout/CustomPagination";
import { PerformerType } from "../../../utils/enums";

export default (props: RA.ListProps) => {
  const styles = useStyles();
  const filters = [
    <RA.TextInput
      label="Search by performer name"
      source="q"
      alwaysOn
      className={styles.search}
    />,
    <RA.SelectInput source="type" choices={PerformerType} alwaysOn />,
    <RA.BooleanInput
      label="Ignored"
      source="ignore"
      defaultValue={false}
      alwaysOn
    />,
  ];

  return (
    <RA.List
      filters={filters}
      pagination={<CustomPagination />}
      perPage={50}
      {...props}
    >
      <RA.Datagrid rowClick="edit">
        <RA.TextField source="id" />
        <RA.TextField source="name" />
        <RA.DateField
          source="createdAt"
          locales="en-us"
          options={{ timeZone: "America/Los_Angeles" }}
        />
        <RA.EditButton />
        <RA.ShowButton />
      </RA.Datagrid>
    </RA.List>
  );
};
