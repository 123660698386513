import * as RA from "react-admin";
import { Visibility, Event, RemoveCircle } from "@material-ui/icons";
import EventForm from "./_Form";
import PersistedNameContainer from "../../common/PersistedNameContainer";
import {
  boxOfficeEventUrl,
  triggerTevoEventSyncUrl,
  triggerEventTevoListingsPurgeUrl,
} from "../../../utils/urls";
import useAuthentication from "../../hooks/useAuthentication";
import {
  getDataFromCustomRoute,
  postDataToCustomRoute,
} from "../../providers/DataProvider";
import React from "react";

const Container = (props: any) => {
  if (!props.record) {
    return null;
  }

  const venue = RA.useGetOne("venues", props.record.venueId).data as RA.Record;

  const eventLink = venue
    ? boxOfficeEventUrl(venue.slug, props.record.slug)
    : null;

  return (
    <PersistedNameContainer name={props.record.name} link={eventLink}>
      <EventForm view="edit" {...props} />
    </PersistedNameContainer>
  );
};

const EditActions = ({ eventId }: { eventId: string | undefined }) => {
  const { user, authToken } = useAuthentication();

  if (!eventId || !authToken) {
    return null;
  }

  return (
    <RA.TopToolbar>
      <RA.Button
        onClick={() =>
          window.location.assign(
            `${process.env.REACT_APP_APPLICATION_URL}/#/events/${eventId}/show`
          )
        }
        label={"Show"}
      >
        <Visibility />
      </RA.Button>
      <RA.Button
        onClick={async () => {
          const url = triggerTevoEventSyncUrl(eventId);
          await getDataFromCustomRoute(url, authToken, user);
          alert("Sync with tevo scheduled");
        }}
        label="Schedule Sync with Tevo"
      >
        <Event />
      </RA.Button>
      <RA.Button
        onClick={async () => {
          const url1 = triggerEventTevoListingsPurgeUrl();
          await postDataToCustomRoute(url1, { eventId }, authToken, user);
          const url2 = triggerTevoEventSyncUrl(eventId);
          await getDataFromCustomRoute(url2, authToken, user);
          alert("Events deleted and sync with tevo started");
        }}
        label="Delete Listings and Re-Sync with Tevo"
      >
        <RemoveCircle />
      </RA.Button>
    </RA.TopToolbar>
  );
};

export default (props: RA.EditProps) => (
  <RA.Edit {...props} actions={<EditActions eventId={props.id} />}>
    <Container {...props} />
  </RA.Edit>
);
