import { ReactNode } from "react";
import { Auth0Provider } from "@auth0/auth0-react";

type Props = {
  children: ReactNode;
};

const AuthProvider = ({ children }: Props) => {
  if (!process.env.REACT_APP_AUTH0_DOMAIN) {
    throw new Error(
      "AuthProvider Error: Must have env variable REACT_APP_AUTH0_DOMAIN set"
    );
  }

  if (!process.env.REACT_APP_AUTH0_CLIENT_ID) {
    throw new Error(
      "AuthProvider Error: Must have env variable REACT_APP_AUTH0_CLIENT_ID set"
    );
  }

  return (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
      authorizationParams={{
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        redirect_uri: process.env.REACT_APP_AUTH0_REDIRECT_URI,
      }}
    >
      {children}
    </Auth0Provider>
  );
};

export default AuthProvider;
