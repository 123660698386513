import * as RA from "react-admin";
import { Create, Mail } from "@material-ui/icons";
import { BookingMethod, OrderStatus } from "../../../utils/enums";
import PersistedNameContainer from "../../common/PersistedNameContainer";
import useAuthentication from "../../hooks/useAuthentication";
import { getDataFromCustomRoute } from "../../providers/DataProvider";
import {
  boxOfficeOrderReceiptPageUrl,
  triggerMailerCustomerEmailUrl,
  triggerMailerLpEmailUrl,
} from "../../../utils/urls";
import OrderDataAtTimeOfPurchase from "./components/OrderDataAtTimeOfPurchase";

export default (props: any) => (
  <RA.Show {...props} actions={<ShowActions orderId={props.id} />}>
    <Container {...props} />
  </RA.Show>
);

const Container = (props: any) => {
  const orderNumber = props.record.number;
  const receiptLink = boxOfficeOrderReceiptPageUrl(orderNumber);

  return (
    <PersistedNameContainer
      name={`Order Number: ${orderNumber}`}
      receiptLink={receiptLink}
    >
      <OrdersShow {...props} />
    </PersistedNameContainer>
  );
};

const ShowActions = ({ orderId }: { orderId: string | undefined }) => {
  if (!orderId) {
    return null;
  }

  const { user, authToken } = useAuthentication();
  const order = RA.useGetOne("orders", orderId).data as RA.Record;

  if (!order) {
    return null;
  }

  const canSendEmail =
    order.status === "approved" ||
    order.status === "paid" ||
    order.status === "delivered";

  if (!order || !authToken) {
    return null;
  }

  return (
    <RA.TopToolbar>
      <RA.Button
        onClick={() =>
          window.location.assign(
            `${process.env.REACT_APP_APPLICATION_URL}/#/orders/${orderId}`
          )
        }
        label={"Edit"}
      >
        <Create />
      </RA.Button>
      {canSendEmail && (
        <RA.Button
          onClick={() => {
            const url = triggerMailerCustomerEmailUrl(orderId);
            getDataFromCustomRoute(url, authToken, user).then(function (res) {
              if (res.ok) {
                alert("Email sent");
              } else {
                alert("Failure, something went wrong");
              }
            });
          }}
          label="Resend sale success email"
        >
          <Mail />
        </RA.Button>
      )}
      {canSendEmail && (
        <RA.Button
          onClick={() => {
            const url = triggerMailerLpEmailUrl(orderId);
            getDataFromCustomRoute(url, authToken, user).then(function (res) {
              if (res.ok) {
                alert("Email sent");
              } else {
                alert("Failure, something went wrong");
              }
            });
          }}
          label="Resend LP success email"
        >
          <Mail />
        </RA.Button>
      )}
    </RA.TopToolbar>
  );
};

const OrdersShow = ({ ...props }) => (
  <RA.TabbedShowLayout {...props}>
    <RA.Tab label="Properties">
      <RA.DateField
        source="createdAt"
        locales="en-us"
        options={{ timeZone: "America/Los_Angeles" }}
      />
      <RA.SelectField source="status" choices={OrderStatus} />
      <RA.TextField source="reviewedBy" />
      <RA.DateField source="reviewedDate" />
      <RA.SelectField source="type" choices={BookingMethod} />
      <RA.TextField source="wholesalePrice" />
      <RA.TextField source="subtotal" />
      <RA.TextField source="tax" />
      <RA.TextField source="salePrice" />
      <RA.TextField source="marginApplied" />
      <RA.TextField source="numberTickets" />
      <RA.TextField source="tevoOrderId" />
      <RA.TextField source="lastFourCC" label="Last four of credit card" />
      <RA.TextField source="transactionId" />
      <RA.TextField source="fraudScore" />
      <RA.TextField source="fraudRisk" />
      <RA.ReferenceField
        label="Offline Sale"
        source="listingId"
        reference="listings"
        link={false}
      >
        <RA.BooleanField source="offlineOnly" />
      </RA.ReferenceField>
      <OrderDataAtTimeOfPurchase props={props} />
    </RA.Tab>
    <RA.Tab label="Relationships">
      <RA.ReferenceField
        label="Listing"
        source="listingId"
        reference="listings"
      >
        <RA.ChipField source="id" />
      </RA.ReferenceField>
      <RA.ReferenceField
        label="Customer"
        source="customerId"
        reference="customers"
      >
        <RA.ChipField source="email" />
      </RA.ReferenceField>
    </RA.Tab>
  </RA.TabbedShowLayout>
);
