import * as RA from "react-admin";
import { Create, Event, RemoveCircle } from "@material-ui/icons";
import ListingsDataGrid from "../listings/listingsDataGrid";
import PersistedNameContainer from "../../common/PersistedNameContainer";
import CustomPagination from "../../layout/CustomPagination";
import { convertShTzToIntlTz } from "../../../utils/timeZones";
import {
  boxOfficeEventUrl,
  triggerEventTevoListingsPurgeUrl,
  triggerTevoEventSyncUrl,
} from "../../../utils/urls";
import useAuthentication from "../../hooks/useAuthentication";
import {
  getDataFromCustomRoute,
  postDataToCustomRoute,
} from "../../providers/DataProvider";
import React from "react";

const Container = (props: any) => {
  if (!props.record) {
    return null;
  }

  const venue = RA.useGetOne("venues", props.record.venueId).data as RA.Record;

  const eventLink = venue
    ? boxOfficeEventUrl(venue.slug, props.record.slug)
    : null;

  return (
    <PersistedNameContainer name={props.record.name} link={eventLink}>
      <EventShow {...props} />
    </PersistedNameContainer>
  );
};

const EventShow = ({ ...props }) => {
  const { referenceRecord } = RA.useReference({
    reference: "venues",
    id: props.record.venueId,
  }) as Record<any, any>;

  if (!referenceRecord) {
    return null;
  }

  return (
    <RA.TabbedShowLayout {...props}>
      <RA.Tab label="Properties">
        <RA.ImageField source="featuredImage" />
        <RA.BooleanField source="featured" />
        <RA.TextField source="name" />
        <RA.ReferenceField label="Venue" source="venueId" reference="venues">
          <RA.TextField source="name" />
        </RA.ReferenceField>
        <RA.DateField
          source="occursAt"
          showTime
          locales="en-us"
          options={{ timeZone: convertShTzToIntlTz(referenceRecord.timeZone) }}
        />
        <RA.TextField source="slug" />
        <RA.TextField source="ticketEvoId" label="Ticket evo Id" />
        <RA.NumberField source="margin" />
        <RA.NumberField source="privateSuiteThreshold" />
        <RA.BooleanField source="expired" />
        <RA.BooleanField source="highPriority" />
        <RA.ArrayField source="categories">
          <RA.SingleFieldList linkType={false}>
            <RA.ChipField source="field" />
          </RA.SingleFieldList>
        </RA.ArrayField>
        <RA.DateField
          source="createdAt"
          locales="en-us"
          options={{ timeZone: "America/Los_Angeles" }}
        />
        <RA.DateField
          source="ticketEvoUpdatedAt"
          showTime
          locales="en-us"
          options={{ timeZone: "America/Los_Angeles" }}
        />
      </RA.Tab>
      <RA.Tab label="Relationships">
        <RA.ReferenceField label="Venue" source="venueId" reference="venues">
          <RA.ChipField source="name" />
        </RA.ReferenceField>
        <RA.ReferenceManyField
          label="Listings"
          reference="listings"
          target="eventId"
          pagination={<CustomPagination />}
          perPage={50}
        >
          <ListingsDataGrid
            showEventsData={false}
            showStatusColumn={true}
            showAssetData={true}
          />
        </RA.ReferenceManyField>
        <RA.ReferenceManyField
          label="Performers"
          reference="performers"
          target="performersByEventId"
        >
          <RA.SingleFieldList>
            <RA.ChipField source="name" />
          </RA.SingleFieldList>
        </RA.ReferenceManyField>
      </RA.Tab>
    </RA.TabbedShowLayout>
  );
};

const ShowActions = ({ eventId }: { eventId: string | undefined }) => {
  const { user, authToken } = useAuthentication();

  if (!eventId || !authToken) {
    return null;
  }

  return (
    <RA.TopToolbar>
      <RA.Button
        onClick={() =>
          window.location.assign(
            `${process.env.REACT_APP_APPLICATION_URL}/#/events/${eventId}`
          )
        }
        label={"Edit"}
      >
        <Create />
      </RA.Button>
      <RA.Button
        onClick={async () => {
          const url = triggerTevoEventSyncUrl(eventId);
          await getDataFromCustomRoute(url, authToken, user);
          alert("Sync with tevo scheduled");
        }}
        label="Schedule Sync with Tevo"
      >
        <Event />
      </RA.Button>
      <RA.Button
        onClick={async () => {
          const url1 = triggerEventTevoListingsPurgeUrl();
          await postDataToCustomRoute(url1, { eventId }, authToken, user);
          const url2 = triggerTevoEventSyncUrl(eventId);
          await getDataFromCustomRoute(url2, authToken, user);
          alert("Events deleted and sync with tevo started");
        }}
        label="Delete Listings and Re-Sync with Tevo"
      >
        <RemoveCircle />
      </RA.Button>
    </RA.TopToolbar>
  );
};

export default (props: RA.ShowProps) => (
  <RA.Show {...props} actions={<ShowActions eventId={props.id} />}>
    <Container {...props} />
  </RA.Show>
);
