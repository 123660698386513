import * as RA from "react-admin";

export default (props: RA.ShowProps) => (
  <RA.Show {...props}>
    <RA.SimpleShowLayout>
      <RA.ReferenceField label="Venue" source="venueId" reference="venues">
        <RA.TextField source="name" />
      </RA.ReferenceField>
      <RA.TextField source="category" />
      <RA.TextField source="performerSlug" />
      <RA.TextField source="viewerId" label="Viewer Id" />
      <RA.TextField source="seatSelectionMapId" label="Seat selection map id" />
    </RA.SimpleShowLayout>
  </RA.Show>
);
