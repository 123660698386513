import * as RA from "react-admin";
import { getAmenitiesWithDefaultsBySeatingType } from "../../utils/amenities";

const BooleanInputs = ({
  record = {},
  seatingType,
}: {
  record: Record<any, any>;
  seatingType: string;
}) => {
  const propertiesList = getAmenitiesWithDefaultsBySeatingType(seatingType);

  if (!propertiesList) {
    return null;
  }

  const properties = Object.keys(propertiesList);

  return (
    <>
      {properties.map((property, index) => {
        const initialValue =
          record && record.amenities
            ? record.amenities[property]
            : // @ts-ignore
              propertiesList[property];
        return (
          <RA.BooleanInput
            source={property}
            key={index}
            initialValue={initialValue}
          />
        );
      })}
    </>
  );
};

export default BooleanInputs;
