import * as RA from "react-admin";

const CodeBlockField = (props: RA.FieldProps) => {
  const { source } = props;
  const record = RA.useRecordContext(props);

  if (!record || !source) {
    return null;
  }

  const value = record[source];

  return (
    <pre>
      <code>{JSON.stringify(value, null, 2)}</code>
    </pre>
  );
};

export default CodeBlockField;
