import * as RA from "react-admin";
import PerformerForm from "./_Form";
import PersistedNameContainer from "../../common/PersistedNameContainer";

export default (props: RA.CreateProps) => (
  <RA.Create {...props}>
    <PersistedNameContainer name="Create new performer">
      <PerformerForm view="create" />
    </PersistedNameContainer>
  </RA.Create>
);
