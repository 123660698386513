import * as RA from "react-admin";
import OrdersDataGrid from "../orders/ordersDataGrid";
import CustomPagination from "../../layout/CustomPagination";

export default (props: RA.ShowProps) => (
  <RA.Show {...props}>
    <CustomersShow {...props} />
  </RA.Show>
);

const CustomersShow = ({ ...props }) => (
  <RA.TabbedShowLayout {...props}>
    <RA.Tab label="Properties">
      <RA.TextField source="email" />
      <RA.TextField source="firstName" />
      <RA.TextField source="lastName" />
      <RA.TextField source="phone" />
      <RA.TextField source="company" />
      <RA.TextField source="address" />
      <RA.TextField source="city" />
      <RA.TextField source="state" />
      <RA.TextField source="zip" />
      <RA.DateField
        source="createdAt"
        locales="en-us"
        options={{ timeZone: "America/Los_Angeles" }}
      />
    </RA.Tab>
    <RA.Tab label="Relationships">
      <RA.ReferenceManyField
        label="Orders"
        reference="orders"
        target="customerId"
        pagination={<CustomPagination />}
        perPage={50}
      >
        <OrdersDataGrid />
      </RA.ReferenceManyField>
    </RA.Tab>
  </RA.TabbedShowLayout>
);
