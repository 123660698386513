import * as RA from "react-admin";
import CustomToolBar from "../../../layout/CustomToolBar";

const Form = ({ view = "edit", ...props }) => {
  const { record } = props;
  const venueId = record.venueId;

  return (
    <RA.SimpleForm
      {...props}
      initialValues={{ venueId }}
      toolbar={
        <CustomToolBar
          view={view}
          deleteRedirectPath={`/venues/${venueId}/1`}
        />
      }
      redirect={`/venues/${venueId}/1`}
    >
      <RA.ReferenceField label="Venue" source="venueId" reference="venues">
        <RA.TextField source="name" />
      </RA.ReferenceField>
      <RA.ReferenceField label="Asset" source="assetId" reference="assets">
        <RA.TextField source="name" />
      </RA.ReferenceField>
      <RA.TextInput source="section" validate={RA.required()} />
      <RA.TextInput source="row" validate={RA.required()} />
    </RA.SimpleForm>
  );
};

export default Form;
