import * as RA from "react-admin";
import { Country, TimeZone, VenueType } from "../../../utils/enums";
import PersistedNameContainer from "../../common/PersistedNameContainer";
import NumberInput from "../../common/NumberInput";
import ImageUploader from "../../common/ImageUploader";
import React from "react";

export default (props: RA.CreateProps) => (
  <RA.Create {...props}>
    <PersistedNameContainer name="Create New Venue">
      <VenueCreate {...props} />
    </PersistedNameContainer>
  </RA.Create>
);

const VenueCreate = (props: any) => {
  return (
    <RA.TabbedForm {...props} redirect="list">
      <RA.FormTab label="Properties">
        <ImageUploader
          source="featuredImage"
          label="Featured Image"
          helperText="Hard Landscape Style Image. Min 1500px wide."
        />
        <RA.TextInput source="name" validate={RA.required()} />
        <RA.SelectInput
          source="venueType"
          choices={VenueType}
          validate={RA.required()}
        />
        <NumberInput
          source="ticketEvoId"
          label="Ticket Evo Id"
          validate={RA.required()}
        />
        <NumberInput source="margin" />
        <RA.TextInput source="slug" validate={RA.required()} />
        <RA.SelectInput
          source="country"
          validate={RA.required()}
          choices={Country}
        />
        <RA.NumberInput source="lat" validate={RA.required()} />
        <RA.NumberInput source="long" validate={RA.required()} />
        <RA.SelectInput
          source="country"
          validate={RA.required()}
          choices={Country}
        />
        <RA.SelectInput
          source="timeZone"
          choices={TimeZone}
          validate={RA.required()}
        />
        <NumberInput
          source="privateSuiteThreshold"
          helperText="Price that determines whether a suite is private or not coming from Tevo. Optional."
        />
        <RA.TextInput source="ticketPortalUrl" />
        <RA.BooleanInput
          source="syncExistingListings"
          label="Include in existing listings sync"
        />
        <RA.ArrayInput source="listingTerms">
          <RA.SimpleFormIterator>
            <RA.TextInput source="field" label="Listing term item" />
          </RA.SimpleFormIterator>
        </RA.ArrayInput>
        <RA.ArrayInput source="listingExclusions">
          <RA.SimpleFormIterator>
            <RA.TextInput source="field" label="Listing exclusion item" />
          </RA.SimpleFormIterator>
        </RA.ArrayInput>
        <RA.ArrayInput source="synonyms">
          <RA.SimpleFormIterator>
            <RA.TextInput source="field" label="" />
          </RA.SimpleFormIterator>
        </RA.ArrayInput>
      </RA.FormTab>
    </RA.TabbedForm>
  );
};
