import * as RA from "react-admin";
import CreateListingLink from "../../common/CreateListingLink";

const AssetsDataGrid = ({
  showVenueData = true,
  showListingPartnerData = true,
}) => (
  <RA.Datagrid rowClick="edit">
    <RA.TextField source="id" />
    {showVenueData && (
      <RA.ReferenceField
        label="Venue"
        source="venueId"
        reference="venues"
        sortable={false}
      >
        <RA.TextField source="name" />
      </RA.ReferenceField>
    )}
    <RA.TextField source="name" />
    <RA.TextField source="label" />
    <RA.TextField source="seatingType" label="Seating Type" />
    {showListingPartnerData && (
      <RA.ReferenceField
        label="LP First Name"
        source="listingPartnerId"
        reference="listingPartners"
        sortable={false}
      >
        <RA.TextField source="firstName" />
      </RA.ReferenceField>
    )}
    {showListingPartnerData && (
      <RA.ReferenceField
        label="LP Last Name"
        source="listingPartnerId"
        reference="listingPartners"
        sortable={false}
      >
        <RA.TextField source="lastName" />
      </RA.ReferenceField>
    )}
    {showListingPartnerData && (
      <RA.ReferenceField
        label="LP Email"
        source="listingPartnerId"
        reference="listingPartners"
        sortable={false}
      >
        <RA.TextField source="email" />
      </RA.ReferenceField>
    )}
    <CreateListingLink referenceType="asset" />
    <RA.EditButton />
    <RA.ShowButton />
  </RA.Datagrid>
);

export default AssetsDataGrid;
