import { cloneElement } from "react";
import useStyles from "../hooks/Styles";
import HtmlSanitize from "./HtmlSanitize";
import { Typography, Link } from "@material-ui/core";

const PersistedNameContainer = (props: any) => {
  const styles = useStyles();

  return (
    <HtmlSanitize>
      <Typography variant="h5" className={styles.venueName}>
        {props.name ? props.name : props.record.name}
      </Typography>
      {props.link && (
        <Typography variant="subtitle1" className={styles.resourceLink}>
          Website url: {` `}
          <Link href={props.link} target="_blank">
            {props.link}
          </Link>
        </Typography>
      )}
      {props.checkoutlink && (
        <Typography variant="subtitle1" className={styles.resourceLink}>
          Checkout url: {` `}
          <Link href={props.checkoutlink} target="_blank">
            {props.checkoutlink}
          </Link>
        </Typography>
      )}
      {props.receiptLink && (
        <Typography variant="subtitle1" className={styles.resourceLink}>
          Receipt url: {` `}
          <Link href={props.receiptLink} target="_blank">
            {props.receiptLink}
          </Link>
        </Typography>
      )}
      {cloneElement(props.children, { ...props })}
    </HtmlSanitize>
  );
};

export default PersistedNameContainer;
