import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  alert: {
    marginTop: "1rem",
    marginBottom: "1rem",
  },
  search: {
    width: 300,
  },
  venueName: {
    margin: "1rem",
  },
  resourceLink: {
    margin: "1rem",
  },
  titleAlert: {
    margin: "1rem",
  },
  marginBottom: {
    marginBottom: "1rem",
  },
  marginRight: {
    marginRight: "1rem",
  },
  marginLeft: {
    marginLeft: "1rem",
  },
  divider: {
    marginTop: "1rem",
    marginBottom: "1rem",
  },
  header: {
    marginTop: "1rem",
    marginBottom: "1rem",
  },
  input: {
    maxWidth: "500px",
    width: "100%",
    textTransform: "capitalize",
  },
  displayBlock: {
    display: "block",
  },
  toolBar: {
    display: "flex",
    justifyContent: "space-between",
  },
  deleteButton: {
    color: "#c64c38",
    "&:hover": {
      backgroundColor: "#f5f5f5",
    },
  },
  displayNone: {
    display: "none",
  },
  threeDViewerContainer: {
    maxWidth: "800px",
    width: "100%",
    textTransform: "capitalize",
    paddingTop: "40px",
  },
  threeDViewerPlugin: {
    marginBottom: "40px",
  },
  assetAttachButton: {
    marginLeft: "10px",
    height: "56px",
    marginBottom: "15px",
  },
});

export default useStyles;
