import * as RA from "react-admin";
import PersistedNameContainer from "../../common/PersistedNameContainer";

export default (props: RA.ShowProps) => (
  <RA.Show {...props}>
    <PersistedNameContainer {...props}>
      <MarketShow {...props} />
    </PersistedNameContainer>
  </RA.Show>
);

const MarketShow = ({ ...props }) => (
  <RA.SimpleShowLayout {...props}>
    <RA.ImageField source="featuredImage" />
    <RA.TextField source="name" />
    <RA.TextField source="slug" />
    <RA.TextField source="lat" />
    <RA.TextField source="long" />
  </RA.SimpleShowLayout>
);
