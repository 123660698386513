import * as RA from "react-admin";
import EventForm from "./_Form";
import PersistedNameContainer from "../../common/PersistedNameContainer";

export default (props: RA.CreateActionsProps) => (
  <RA.Create {...props}>
    <PersistedNameContainer name="Create new event">
      <EventForm view="create" />
    </PersistedNameContainer>
  </RA.Create>
);
