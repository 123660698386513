import * as RA from "react-admin";
import ListingPartnerForm from "./_Form";
import PersistedNameContainer from "../../common/PersistedNameContainer";

export default (props: RA.CreateProps) => (
  <RA.Create {...props}>
    <PersistedNameContainer name="Create new listing partner">
      <ListingPartnerForm view="create" />
    </PersistedNameContainer>
  </RA.Create>
);
