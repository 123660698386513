import * as RA from "react-admin";
import OrdersForm from "./_Form";
import PersistedNameContainer from "../../common/PersistedNameContainer";

export default (props: RA.CreateProps) => (
  <RA.Create {...props}>
    <PersistedNameContainer name="Create new order">
      <OrdersForm view="create" />
    </PersistedNameContainer>
  </RA.Create>
);
