import * as RA from "react-admin";
import ListingPartnerForm from "./_Form";
import PersistedNameContainer from "../../common/PersistedNameContainer";

export default (props: RA.EditProps) => (
  <RA.Edit {...props}>
    <Container />
  </RA.Edit>
);

const Container = ({ ...props }) => (
  <PersistedNameContainer
    name={`Listing partner: ${props.record.email}`}
    {...props}
  >
    <ListingPartnerForm view="edit" />
  </PersistedNameContainer>
);
