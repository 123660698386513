import * as RA from "react-admin";

export default (props: RA.ShowProps) => (
  <RA.Show {...props}>
    <RA.SimpleShowLayout>
      <RA.TextField source="displayName" />
      <RA.TextField source="name" />
      <RA.TextField source="maxSeatsAvailable" />
      <RA.TextField source="venueSection" />
      <RA.TextField source="seatingType" />
      <RA.TextField source="cateringType" />
      <RA.TextField source="notes" />
    </RA.SimpleShowLayout>
  </RA.Show>
);
