import { Fragment, ReactElement } from "react";

type ElementType = {
  basePath?: string;
  record?: Record<any, any>;
  resource?: string;
  children: ReactElement | ReactElement[];
};

const HtmlSanitize = ({ children }: ElementType) => (
  <Fragment>{children}</Fragment>
);

export default HtmlSanitize;
