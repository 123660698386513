import AuthProvider from "./providers/AuthProvider";
import Dashboard from "./Dashboard";
import qs from "query-string";
import ErrorPage from "./layout/ErrorPage";

const App = () => {
  const urlParams = qs.parse(window.location.search);

  if (urlParams.error === "unauthorized") {
    return <ErrorPage />;
  }

  return (
    <AuthProvider>
      <Dashboard />
    </AuthProvider>
  );
};

export default App;
