import * as RA from "react-admin";
import TevoMappingsForm from "./_Form";

export default (props: RA.EditProps) => {
  return (
    <RA.Edit {...props}>
      <TevoMappingsForm view="edit" />
    </RA.Edit>
  );
};
