export const getAmenitiesWithDefaultsBySeatingType = (seatingType: string) => {
  switch (seatingType) {
    case "suite":
      return {
        privateRestroom: false,
        suiteAttendant: true,
        vipEntrance: true,
        climateControlled: false,
        accessToBar: false,
        loungeRestaurantAccess: false,
        paddedSeating: true,
        inSuiteOrdering: true,
        accessToConcessions: true,
      };
    case "vip_seating":
      return {
        paddedSeating: true,
        exclusiveRestroom: true,
        loungeRestaurantAccess: false,
        inSeatService: false,
        vipEntrance: true,
        accessToConcessions: true,
        accessToBar: false,
      };
    case "vip_club":
      return {
        paddedSeating: true,
        exclusiveRestroom: true,
        loungeRestaurantAccess: true,
        inSeatService: false,
        vipEntrance: true,
        accessToBar: false,
      };
    case "vip_box":
      return {
        inSeatService: true,
        paddedSeating: true,
        vipClubAccess: false,
        exclusiveRestroom: false,
        loungeRestaurantAccess: false,
        vipEntrance: false,
        accessToBar: false,
        stageView: true,
      };
    case "loge_box":
      return {
        inSeatService: false,
        vipClubAccess: false,
        exclusiveRestroom: true,
        vipEntrance: true,
        accessToBar: false,
      };
    case "glass_seats":
      return {
        loungeRestaurantAccess: false,
        inSeatService: false,
        playerInteraction: true,
        vipEntrance: true,
        accessToBar: false,
        accessToConcessions: true,
        exclusiveRestroom: true,
      };
    case "courtside_seats":
      return {
        loungeRestaurantAccess: true,
        inSeatService: false,
        playerInteraction: true,
        vipEntrance: true,
        accessToBar: false,
        accessToConcessions: true,
        exclusiveRestroom: true,
        paddedSeating: true,
      };
    case "vip_package":
      return {
        loungeRestaurantAccess: false,
        exclusiveRestroom: false,
        accessToBar: false,
        pregameTailgate: false,
        merchCredit: false,
        meetAndGreet: false,
        includesHotel: false,
        playerInteraction: false,
        vipEntrance: false,
        limoService: false,
      };
    case "floor_seats":
    case "front_row":
    case "vip_tailgate":
    case "shared_access":
    default:
      return null;
  }
};

export const getEntireAmenitiesList = () => [
  "privateRestroom",
  "suiteAttendant",
  "vipEntrance",
  "climateControlled",
  "accessToBar",
  "loungeRestaurantAccess",
  "paddedSeating",
  "inSuiteOrdering",
  "accessToConcessions",
  "exclusiveRestroom",
  "inSeatService",
  "vipClubAccess",
  "stageView",
  "playerInteraction",
  "pregameTailgate",
  "merchCredit",
  "meetAndGreet",
  "includesHotel",
  "limoService",
];
