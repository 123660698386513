// @ts-nocheck
import { memo, useRef, useEffect } from "react";
import {
  MapContainer,
  ImageOverlay,
  ZoomControl,
  CircleMarker,
  useMap,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";

const MAP_WIDTH = 4096;

const MapPinInput = ({
  image,
  isActiveTab,
  x,
  y,
  onUpdate,
}: {
  image: string;
  isActiveTab: boolean;
  x: number;
  y: number;
  onUpdate: (latLong: Record<any, any>) => void;
}) => {
  const ref = useRef(null);
  const minZoom = 0;
  const maxZoom = 4;
  const bounds = [
    [0, 0],
    [MAP_WIDTH, MAP_WIDTH],
  ];

  if (!isActiveTab) {
    return null;
  }

  return (
    <MapContainer
      ref={ref}
      style={{ height: "600px", maxWidth: "800px", border: "1px solid #CCC" }}
      height={MAP_WIDTH}
      width={MAP_WIDTH}
      bounds={bounds}
      zoom={2}
      attributionControl={false}
      minZoom={minZoom}
      maxZoom={maxZoom}
      dragging={true}
      zoomControl={false}
      scrollWheelZoom={false}
      center={[0, 0]}
    >
      <VenueImage url={image} maxZoom={maxZoom} />
      <DraggableCircleMarker position={[x, y]} onUpdate={onUpdate} />
      <ZoomControl position="topright" />
    </MapContainer>
  );
};

const DraggableCircleMarker = ({ position, onUpdate }) => {
  const circleRef = useRef();

  const handleMouseDown = () => {
    const circle = circleRef.current;
    if (!circle || !circle._path) return;
    circle._map.dragging.disable();
    circle._map.off("mousemove");
    circle._map.on("mousemove", handleMouseMove);
    circle._map.once("mouseup", handleMouseUp);
  };

  const handleMouseMove = (e) => {
    const circle = circleRef.current;
    if (!circle || !circle._path) return;
    circle.setLatLng(e.latlng);
    onUpdate(e.latlng);
  };

  const handleMouseUp = () => {
    const circle = circleRef.current;
    if (!circle || !circle._path) return;
    circle._map.dragging.enable();
    circle._map.off("mousemove", handleMouseMove);
  };

  useEffect(() => {
    const circle = circleRef.current;
    if (circle) {
      circle.getElement().addEventListener("mousedown", handleMouseDown);
    }
    return () => {
      if (circle) {
        circle.getElement().removeEventListener("mousedown", handleMouseDown);
      }
    };
  }, []);

  return (
    <CircleMarker
      ref={circleRef}
      center={position}
      radius={10}
      pathOptions={{
        zIndexOffset: 1000,
        radius: 10,
        fillOpacity: 1.0,
        fillColor: "#E11D48",
        stroke: true,
        color: "#FFFFFF",
      }}
      eventHandlers={{ mousedown: handleMouseDown }}
    />
  );
};

const VenueImage = ({ url, maxZoom }: { url: string; maxZoom: number }) => {
  const map = useMap();
  const southWest = map.unproject([0, MAP_WIDTH], maxZoom);
  const northEast = map.unproject([MAP_WIDTH, 0], maxZoom);
  const bounds = [southWest, northEast];

  return <ImageOverlay url={url} bounds={bounds} />;
};

export default memo(MapPinInput);
