import * as RA from "react-admin";
import { OrderStatus, BookingMethod } from "../../../utils/enums";

const OrdersDataGrid = () => {
  return (
    <RA.Datagrid rowClick="edit">
      <RA.TextField source="number" label="Order number" />
      <RA.DateField
        source="createdAt"
        locales="en-us"
        options={{ timeZone: "America/Los_Angeles" }}
      />
      <RA.ReferenceField
        label="Event"
        source="eventId"
        reference="events"
        link="show"
        sortable={false}
      >
        <RA.TextField source="name" />
      </RA.ReferenceField>
      <RA.ReferenceField
        label="Asset"
        source="assetId"
        reference="assets"
        link="show"
        sortable={false}
      >
        <RA.TextField source="name" />
      </RA.ReferenceField>
      <RA.ReferenceField
        label="Listing Partner"
        source="listingPartnerId"
        reference="listingPartners"
        link="show"
        sortable={false}
      >
        <RA.TextField source="email" />
      </RA.ReferenceField>
      <RA.ReferenceField
        label="Customer"
        source="customerId"
        reference="customers"
        link="show"
      >
        <RA.TextField source="email" />
      </RA.ReferenceField>
      <RA.SelectField source="status" choices={OrderStatus} />
      <RA.SelectField source="type" choices={BookingMethod} />
      <RA.NumberField source="fraudScore" />
      <RA.NumberField
        source="subtotal"
        options={{
          style: "currency",
          currency: "USD",
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        }}
      />
      <RA.NumberField
        source="tax"
        options={{
          style: "currency",
          currency: "USD",
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        }}
      />
      <RA.NumberField
        source="salePrice"
        options={{
          style: "currency",
          currency: "USD",
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        }}
      />
      <RA.EditButton />
      <RA.ShowButton />
    </RA.Datagrid>
  );
};

export default OrdersDataGrid;
