import * as RA from "react-admin";
import useStyles from "../../hooks/Styles";
import AssetsDataGrid from "./assetsDataGrid";
import CustomPagination from "../../layout/CustomPagination";

export default (props: RA.ListProps) => {
  const styles = useStyles();
  const filters = [
    <RA.TextInput
      label="Search by asset name"
      source="q"
      alwaysOn
      className={styles.search}
    />,
    <RA.BooleanInput
      label="Show Unmapped Assets"
      source="unmappedAssetsOnly"
      defaultValue={false}
      alwaysOn
    />,
    <RA.BooleanInput
      label="Show Ignored Assets"
      source="ignore"
      defaultValue={false}
      alwaysOn
    />,
  ];

  return (
    <RA.List
      pagination={<CustomPagination />}
      perPage={50}
      filters={filters}
      {...props}
    >
      <AssetsDataGrid />
    </RA.List>
  );
};
